import React, { createContext, useCallback, useEffect, useState } from "react";
import { useConfig } from "@genflow/core";
import useProfileUserData from "@genflow/web-auth/hooks/useProfileUserData";
import useCourse from "@genflow/web-courses/hooks/useCourse";
import { useCompletedSessions, useWorkout } from "@genflow/web-workouts";
import {get, flatten, orderBy, flattenDeep, groupBy, keys, merge, parseInt, uniqBy, values } from "lodash";
import transformWorkoutProductToClasses, { isRestDay } from "./utils/transformWorkoutProductToClasses";
import transformCourseToClasses from "./utils/transformCourseToClasses";

import Fuse from "fuse.js";
import { useUser } from "../../hooks/useUser";
import { GUIDE_FIND_WORKOUT_FREQUENCY } from "../../config";
import { CHALLENGES_PRODUCT_ID } from "../../config";
import moment from "moment";

export const ContentManagerContext = createContext();
const isBrowser = typeof window !== `undefined`;

const ContentManagerProvider = ({ children }) => {
  const { authenticated, activeSubscription } = useUser();

  const {
    vendorUuid,
    GUIDE_MAP,
    // GUIDES_ORDER,
    PHYSIO_COURSE_PRODUCT_ID,
    LEARN_EXERCISES_COURSE_PRODUCT_ID,
    NUTRITION_COURSE_PRODUCT_ID,
    FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID,
  } = useConfig();

  const { data: profileUserData, loading: profileUserDataLoading } = useProfileUserData();
  // Getting the users current selected guide on their app
  const profileData = JSON.parse(profileUserData?.data || "{}");
  const currentGuideProductID = profileData?.activeWorkout;

  const { data: guideProduct, loading: currentGuideProductLoading } = useWorkout(currentGuideProductID, null, null, {
    subscriptionACL: true,
    skip: !authenticated || !currentGuideProductID,
  });

  const userWorkoutFrequency = !!currentGuideProductID && GUIDE_FIND_WORKOUT_FREQUENCY(currentGuideProductID);

  // // 7
  // console.log("TEST --->", userWorkoutFrequency);
  // // true
  // console.log(authenticated);

  // Courses TODO: Improve batch class fetch from backend as the solution below is expensive (but it works)!!
  const courseConfig = {
    subscriptionACL: true,
    skip: !authenticated || !userWorkoutFrequency,
  };
  const workoutsConfig = {
    subscriptionACL: true,
    skip: !authenticated || !userWorkoutFrequency,
  };

  const { data: physioData, loading: physioDataLoading } = useCourse(
    vendorUuid,
    PHYSIO_COURSE_PRODUCT_ID,
    courseConfig
  );
  const { data: learnExercisesData, loading: learnExercisesDataLoading } = useCourse(
    vendorUuid,
    LEARN_EXERCISES_COURSE_PRODUCT_ID,
    courseConfig
  );
  const { data: nutritionData, loading: nutritionLoading } = useCourse(
    vendorUuid,
    NUTRITION_COURSE_PRODUCT_ID,
    courseConfig
  );
  const { data: howToBandsData, loading: howToBandsDataLoading } = useCourse(
    vendorUuid,
    FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID,
    courseConfig
  );

  const { data: challengesData, loading: challengesDataLoading } = useCourse(
    vendorUuid,
    CHALLENGES_PRODUCT_ID,
    courseConfig
  );

  // Only grabbing 3 or 4 or 5 or 6x version (same as users current guide) of each guide

  const { data: bootyWorkoutProduct, loading: bootyWorkoutProductLoading } = useWorkout(
    3095,
    null,
    null,
    workoutsConfig
  );
  const { data: hourglassWorkoutProduct, loading: hourglassWorkoutProductLoading } = useWorkout(
    3235,
    null,
    null,
    workoutsConfig
  );
  const { data: smallerWaistWorkoutProduct, loading: smallerWaistWorkoutProductLoading } = useWorkout(
    3100,
    null,
    null,
    workoutsConfig
  );
  const { data: sabriWorkoutProduct, loading: sabriWorkoutProductLoading } = useWorkout(
    3389,
    null,
    null,
    workoutsConfig
  );
  const { data: twentyOneWorkoutProduct, loading: twentyOneProductLoading } = useWorkout(
    3472,
    null,
    null,
    workoutsConfig
  );

  const { data: halloLeanWorkoutProduct, loading: halloLeanProductLoading } = useWorkout(
    3484,
    null,
    null,
    workoutsConfig
  );

  const { data: cutStudentWorkoutProduct, loading: cutStudentProductLoading } = useWorkout(
    3670,
    null,
    null,
    workoutsConfig
  );

  const { data: strongCapableWorkoutProduct, loading: strongCapableProductLoading } = useWorkout(
    4100,
    null,
    null,
    workoutsConfig
  );

  const { data: leanMasWorkoutProduct, loading: leanMasProductLoading } = useWorkout(4115, null, null, workoutsConfig);

  const { data: pilatesWorkoutProduct, loading: pilatesProductLoading } = useWorkout(4141, null, null, workoutsConfig);

  const { data: janWorkoutProduct, loading: janProductLoading } = useWorkout(4148, null, null, workoutsConfig);
  const { data: strongWorkoutProduct, loading: strongProductLoading } = useWorkout(4189, null, null, workoutsConfig);

  const { data: janWeek3Product, loading: janWeek3Loading } = useWorkout(4201, null, null, workoutsConfig);
  const { data: week4Product, loading: week4Loading } = useWorkout(4233, null, null, workoutsConfig);
  const { data: week5Product, loading: week5Loading } = useWorkout(4256, null, null, workoutsConfig);
  const { data: week6Product, loading: week6Loading } = useWorkout(4263, null, null, workoutsConfig);
  const { data: galentineProduct, loading: galentineLoading } = useWorkout(4269, null, null, workoutsConfig);
  const { data: turnItUpProduct, loading: turnItUpLoading } = useWorkout(4276, null, null, workoutsConfig);
  const { data: transformationV2Product, loading: transformationV2Loading } = useWorkout(
    4303,
    null,
    null,
    workoutsConfig
  );
  const { data: transformationV2Wee2Product, loading: transformationV2Week2Loading } = useWorkout(
    4309,
    null,
    null,
    workoutsConfig
  );
  const { data: transformationV2Week3Product, loading: transformationV2Week3Loading } = useWorkout(
    4320,
    null,
    null,
    workoutsConfig
  );
  const { data: transformationV2Week4Product, loading: transformationV2Week4Loading } = useWorkout(
    4352,
    null,
    null,
    workoutsConfig
  );
  const { data: transformationV2Week5Product, loading: transformationV2Week5Loading } = useWorkout(
    4363,
    null,
    null,
    workoutsConfig
  );
  const { data: transformationV2Week6Product, loading: transformationV2Week6Loading } = useWorkout(
    4416,
    null,
    null,
    workoutsConfig
  );
  // const { data: maryBraunWeek1Product, loading: maryBraunWeek1Loading } = useWorkout(
  //   4453,
  //   null,
  //   null,
  //   workoutsConfig
  // );
  // const { data: maryBraunWeek2Product, loading: maryBraunWeek2Loading } = useWorkout(
  //   4470,
  //   null,
  //   null,
  //   workoutsConfig
  // );
  const { data: deloadPilatesBootCampProduct, loading: deloadPilatesBootCampLoading } = useWorkout(
    4444,
    null,
    null,
    workoutsConfig
  );

  const { data: preSummerBootcampProduct, loading: preSummerBootcampLoading } = useWorkout(
    4465,
    null,
    null,
    workoutsConfig
  );
  // const { data: maryBraunWeek3Product, loading: maryBraunWeek3Loading } = useWorkout(
  //   4521,
  //   null,
  //   null,
  //   workoutsConfig
  // );
  // const { data: maryBraunWeek4Product, loading: maryBraunWeek4Loading } = useWorkout(
  //   4526,
  //   null,
  //   null,
  //   workoutsConfig
  // );
  const { data: feelGoodSummerWeek1Product, loading: feelGoodSummerWeek1Loading } = useWorkout(
    4546,
    null,
    null,
    workoutsConfig
  );
  const { data: feelGoodSummerWeek2Product, loading: feelGoodSummerWeek2Loading } = useWorkout(
    4556,
    null,
    null,
    workoutsConfig
  );
  const { data: feelGoodSummerWeek3Product, loading: feelGoodSummerWeek3Loading } = useWorkout(
    4571,
    null,
    null,
    workoutsConfig
  );
  const { data: periodFriendlyProduct, loading: periodFriendlyLoading } = useWorkout(
    4570,
    null,
    null,
    workoutsConfig
  );

  const {data:feelGoodSummerWeek4Product, loading:feelGoodSummerWeek4Loading}=useWorkout(
      4589,
      null,
      null,
      workoutsConfig,
  );


  const {data:feelGoodSummerWeek5Product, loading:feelGoodSummerWeek5Loading}=useWorkout(
      4598,
      null,
      null,
      workoutsConfig
  );

  const {data:feelGoodSummerWeek6Product, loading:feelGoodSummerWeek6Loading}=useWorkout(
      4610,
      null,
      null,
      workoutsConfig
  );

  const {data:bestOfSculptProduct, loading:bestOfSculptLoading}=useWorkout(
      4648,
      null,
      null,
      workoutsConfig,
  );

  const {data:bestOfShredProduct, loading: bestOfShredLoading}=useWorkout(
      4662,
      null,
      null,
      workoutsConfig
  );

  const {data:birthdayProduct, loading:birthdayLoading}=useWorkout(
      4699,
      null,
      null,
      workoutsConfig
  )

  const {data: vacayProduct, loading:vacayLoading}=useWorkout(
    4704,
    null,
    null,
    workoutsConfig
  );

  const {data: vacayWeek2Product, loading:vacayWeek2Loading}=useWorkout(
    4726,
    null,
    null,
    workoutsConfig
  );

  const {data:glowWeek1Product, loading:glowWeek1Loading}=useWorkout(
      4747,
      null,
      null,
      workoutsConfig
  );

  const { data:glowWeek2Product, loading:glowWeek2Loading}=useWorkout(
      4753,
      null,
      null,
      workoutsConfig
  )


const {data:glowWeek3Product, loading:glowWeek3Loading}=useWorkout(
  4789,
  null,
  null,
  workoutsConfig
)


const {data:glowWeek4Product, loading:glowWeek4Loading}=useWorkout(
  4794,
  null,
  null,
  workoutsConfig
)



  const {data:twentyOneVol2Week1Product, loading:twentyOneVol2Week1Loading}=useWorkout(
      4811,
      null,
      null,
      workoutsConfig
  )

  const { data:twentyOneVol2Week2Product, loading:twentyOneVol2Week2Loading}=useWorkout(
      4867,
      null,
      null,
      workoutsConfig
  )

  const { data:twentyOneVol2Week3Product, loading: twentyOneVol2Week3Loading }=useWorkout(
      4899,
      null,
      null,
      workoutsConfig
  );

  const {data: gimmeGummyMoreProduct, loading:gimmeGummyMoreLoading}=useWorkout(
      4920,
      null,
      null,
      workoutsConfig
  );

  const {data:septChallengeWeek1Product, loading:septChallengeWeek1Loading} = useWorkout(
      4930,
      null,
      null,
      workoutsConfig,
  )

  const {data:septChallengeWeek2Product, loading:septChallengeWeek2Loading} = useWorkout(
      4939,
      null,
      null,
      workoutsConfig,
  )

  const {data:feelGoodFitFactoryWeek3Product, loading:feelGoodFitFactoryWeek3Loading} = useWorkout(
      5016,
      null,
      null,
      workoutsConfig,
  )

  const {data:feelGoodFitFactoryWeek4Product, loading:feelGoodFitFactoryWeek4Loading} = useWorkout(
      5058,
      null,
      null,
      workoutsConfig,
  )

  const {data:feelGoodFitFactoryWeek5Product, loading:feelGoodFitFactoryWeek5Loading} = useWorkout(
      5080,
      null,
      null,
      workoutsConfig,
  )

  const {data:feelGoodFitFactoryWeek6Product, loading:feelGoodFitFactoryWeek6Loading} = useWorkout(
      5094,
      null,
      null,
      workoutsConfig,
  )

  const {data:halloLeanPrepProduct, loading:halloLeanPrepLoading} = useWorkout(
      5126,
      null,
      null,
      workoutsConfig,
  );

  const {data:halloLeanHellWeek22Product, loading:halloLeanHellWeek22Loading} = useWorkout(
      5135,
  null,
  null,
  workoutsConfig
  );

  const {data:bestOfStrengthProduct, loading:bestOfStrengthLoading} = useWorkout(
      5160,
  null,
  null,
  workoutsConfig
  );

  const {data:pilatesBodyProduct, loading:pilatesBodyLoading} = useWorkout(
      5195,
      null,
      null,
      workoutsConfig
  );

  const {data:busyGirlBlackFridayWeek1Product, loading:busyGirlBlackFridayWeek1Loading} = useWorkout(
      5233,
      null,
      null,
      workoutsConfig
  );

  const {data:busyGirlBlackFridayWeek2Product, loading:busyGirlBlackFridayWeek2Loading} = useWorkout(
      5250,
  null,
  null,
  workoutsConfig
  )

  const {data:cardioGlowUpProduct, loading:cardioGlowUpLoading} = useWorkout(
      5280,
      null,
      null,
      workoutsConfig
  );

  const {data:leanAndPowerfulProduct, loading:leanAndPowerfulLoading} = useWorkout(
      5289,
      null,
      null,
      workoutsConfig
  );

  const {data:leanMass2022Product, loading:leanMass2022Loading} = useWorkout(
      5309,
      null,
      null,
      workoutsConfig
  );

  const {data:leanMass2022Week2Product, loading:leanMass2022Week2Loading} = useWorkout(
      5336,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodPhase1Product, loading:leanTransformationMethodPhase1Loading} = useWorkout(
      5513,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodPhase2Product, loading:leanTransformationMethodPhase2Loading} = useWorkout(
      5608,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek2Product, loading:leanTransformationMethodWeek2Loading} = useWorkout(
      5457,
      null,
      null,
      workoutsConfig
  );

  const {data:leanMass2022RepackedProduct, loading:leanMass2022RepackedLoading} = useWorkout(
      5474,
      null,
      null,
      workoutsConfig
  );

  const {data:bestOfSculptMonthProduct, loading:bestOfSculptMonthLoading} = useWorkout(
      5354,
      null,
      null,
      workoutsConfig
  );

  const {data:cardioGlowUp4WeeksProduct, loading:cardioGlowUp4WeeksLoading} = useWorkout(
      5342,
      null,
      null,
      workoutsConfig
  );


  const {data:feelGoodFactory4WeeksProduct, loading:feelGoodFactory4WeeksLoading} = useWorkout(
      5137,
      null,
      null,
      workoutsConfig
  );

  const {data:twentyOneVol24WeeksProduct, loading:twentyOneVol24WeeksLoading} = useWorkout(
      4991,
      null,
      null,
      workoutsConfig
  );

  const {data:greensAndGlow4WeeksProduct, loading:greensAndGlow4WeeksLoading} = useWorkout(
      4880,
      null,
      null,
      workoutsConfig
  );

  const {data:vacayGuide4WeeksProduct, loading:vacayGuide4WeeksLoading} = useWorkout(
      4846,
      null,
      null,
      workoutsConfig
  );

  const {data:feelGoodSummer4WeeksProduct, loading:feelGoodSummer4WeeksLoading} = useWorkout(
      4677,
      null,
      null,
      workoutsConfig
  );

  const {data:leanAndStrongTransformationVol24WeeksProduct, loading:leanAndStrongTransformationVol24WeeksLoading} = useWorkout(
      4540,
      null,
      null,
      workoutsConfig
  );

  const {data:leanAndStrongTransformationVol14WeeksProduct, loading:leanAndStrongTransformationVol14WeeksLoading} = useWorkout(
      4535,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek1Product, loading:leanTransformationMethodWeek1Loading} = useWorkout(
      5363,
      null,
      null,
      workoutsConfig
  );

  const {data:busyGirlBlackFriday4WeeksProduct, loading:busyGirlBlackFriday4WeeksLoading} = useWorkout(
      5341,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek3Product, loading:leanTransformationMethodWeek3Loading} = useWorkout(
      5462,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek4Product, loading:leanTransformationMethodWeek4Loading} = useWorkout(
      5467,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek5Product, loading:leanTransformationMethodWeek5Loading} = useWorkout(
      5535,
      null,
      null,
      workoutsConfig
  );

    const {data:leanTransformationMethodWeek6Product, loading:leanTransformationMethodWeek6Loading} = useWorkout(
        5540,
        null,
        null,
        workoutsConfig
    );

  const {data:leanTransformationMethodWeek7Product, loading:leanTransformationMethodWeek7Loading} = useWorkout(
      5545,
      null,
      null,
      workoutsConfig
  );

  const {data:leanTransformationMethodWeek8Product, loading:leanTransformationMethodWeek8Loading} = useWorkout(
      5550,
      null,
      null,
      workoutsConfig
  );

  const {data:recoverAndStrengthenPilatesProduct, loading:recoverAndStrengthenPilatesLoading} = useWorkout(
      5629,
      null,
      null,
      workoutsConfig
  );

  const {data:winterGlowUpProduct, loading:winterGlowUpLoading} = useWorkout(
      5681,
      null,
      null,
      workoutsConfig
  );

  const {data:lowerBodyProduct, loading:lowerBodyLoading} = useWorkout(
      5694,
      null,
      null,
      workoutsConfig
  );

  const {data:upperBodyAbsAndShredProduct, loading:upperBodyAbsAndShredLoading} = useWorkout(
      5713,
      null,
      null,
      workoutsConfig
  );

  const {data:sculptBurnAndShapeProduct, loading:sculptBurnAndShapeLoading} = useWorkout(
      5739,
      null,
      null,
      workoutsConfig
  );

  const {data: ohHiitProduct, loading: ohHiitLoading} = useWorkout(
      5766,
      null,
      null,
      workoutsConfig
  )

  const {data: hourglassSculptAndStrengthenProduct, loading: hourglassSculptAndStrengthenLoading} = useWorkout(
      5784,
      null,
      null,
      workoutsConfig
  )

  const {data: ultimateWeekOfBattlesProduct, loading: ultimateWeekOfBattlesLoading} = useWorkout(
      5811,
      null,
      null,
      workoutsConfig
  )

  const {data: powerPilatesProduct, loading: powerPilatesLoading} = useWorkout(
      5819,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodPhase1Product, loading: leanSummerMethodPhase1Loading} = useWorkout(
      5885,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodPhase2Product, loading: leanSummerMethodPhase2Loading} = useWorkout(
      5985,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek1Product, loading: leanSummerMethodWeek1Loading} = useWorkout(
      5835,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek2Product, loading: leanSummerMethodWeek2Loading} = useWorkout(
      5854,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek3Product, loading: leanSummerMethodWeek3Loading} = useWorkout(
      5897,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek4Product, loading: leanSummerMethodWeek4Loading} = useWorkout(
      5902,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek5Product, loading: leanSummerMethodWeek5Loading} = useWorkout(
      5930,
      null,
      null,
      workoutsConfig,
  )


  const {data: leanSummerMethodWeek6Product, loading: leanSummerMethodWeek6Loading} = useWorkout(
      5935,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek7Product, loading: leanSummerMethodWeek7Loading} = useWorkout(
      5940,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSummerMethodWeek8Product, loading: leanSummerMethodWeek8Loading} = useWorkout(
      5945,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanSecondBirthdayBashProduct, loading: leanSecondBirthdayBashLoading} = useWorkout(
      6044,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyOneVol3Product, loading: twentyOneVol3Loading} = useWorkout(
      6157,
      null,
      null,
      workoutsConfig,
  )


  const {data: twentyOneVol3Week1Product, loading: twentyOneVol3Week1Loading} = useWorkout(
      6103,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyOneVol3Week2Product, loading: twentyOneVol3Week2Loading} = useWorkout(
      6140,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyOneVol3Week3Product, loading: twentyOneVol3Week3Loading} = useWorkout(
      6146,
      null,
      null,
      workoutsConfig,
  )

  const {data: fatBurnCoreGaloreProduct, loading: fatBurnCoreGaloreLoading} = useWorkout(
      6177,
      null,
      null,
      workoutsConfig,
  )

  const {data: legsSculptFatBurnProduct, loading: legsSculptFatBurnLoading} = useWorkout(
      6273,
      null,
      null,
      workoutsConfig,
  )

  const {data: yourStrongestSelfProduct, loading: yourStrongestSelfLoading} = useWorkout(
      6292,
      null,
      null,
      workoutsConfig,
  )

  const {data: shapeBurnPilatesProduct, loading: shapeBurnPilatesLoading} = useWorkout(
      6301,
      null,
      null,
      workoutsConfig,
  )

  const {data: shredWithWeightsProduct, loading: shredWithWeightsLoading} = useWorkout(
      6341,
      null,
      null,
      workoutsConfig,
  )

  const {data: hourGlassShredShapeProduct, loading: hourGlassShredShapeLoading} = useWorkout(
      6358,
      null,
      null,
      workoutsConfig,
  )

  const {data: absAndUpperBodyProduct, loading: absAndUpperBodyLoading} = useWorkout(
      6367,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase1Product, loading: leanEmpoweredMethodPhase1Loading} = useWorkout(
      6368,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase1Week1Product, loading: leanEmpoweredMethodPhase1Week1Loading} = useWorkout(
      6454,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodWeek3Product, loading: leanEmpoweredMethodWeek3Loading} = useWorkout(
      6478,
      null,
      null,
      workoutsConfig,
  )


  const {data: leanEmpoweredMethodPhase1Week2Product, loading: leanEmpoweredMethodPhase1Week2Loading} = useWorkout(
    6473,
    null,
    null,
    workoutsConfig,
)

  const {data: leanEmpoweredMethodPhase1Week4Product, loading: leanEmpoweredMethodPhase1Week4Loading} = useWorkout(
      6483,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase2Week5Product, loading: leanEmpoweredMethodPhase2Week5Loading} = useWorkout(
      6584,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase2Product, loading: leanEmpoweredMethodPhase2Loading} = useWorkout(
      6613,
      null,
      null,
      workoutsConfig,
  )


  const {data: leanEmpoweredMethodPhase2Week6Product, loading: leanEmpoweredMethodPhase2Week6Loading} = useWorkout(
      6588,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase2Week7Product, loading: leanEmpoweredMethodPhase2Week7Loading} = useWorkout(
      6600,
      null,
      null,
      workoutsConfig,
  )

  const {data: halloleanHellWeekProduct, loading: halloleanHellWeekLoading} = useWorkout(
      6623,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanEmpoweredMethodPhase2Week8Product, loading: leanEmpoweredMethodPhase2Week8Loading} = useWorkout(
      6605,
      null,
      null,
      workoutsConfig,
  )

  const {data: pilatesBootcampChallengeWeek1Product, loading: pilatesBootcampChallengeWeek1Loading} = useWorkout(
      6739,
      null,
      null,
      workoutsConfig,
  )

  const {data: pilatesBootcampChallengeWeek2Product, loading: pilatesBootcampChallengeWeek2Loading} = useWorkout(
      6744,
      null,
      null,
      workoutsConfig,
  )

  const {data: pilatesBootcampChallengeProduct, loading: pilatesBootcampChallengeLoading} = useWorkout(
      6804,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyFiveDaysOfLEANMassProduct, loading: twentyFiveDaysOfLEANMassLoading} = useWorkout(
      6831,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyFiveDaysOfLEANMassWeek1Product, loading: twentyFiveDaysOfLEANMassWeek1Loading} = useWorkout(
      6767,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyFiveDaysOfLEANMassWeek2Product, loading: twentyFiveDaysOfLEANMassWeek2Loading} = useWorkout(
      6786,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyFiveDaysOfLEANMassWeek3Product, loading: twentyFiveDaysOfLEANMassWeek3Loading} = useWorkout(
      6791,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyFiveDaysOfLEANMassWeek4Product, loading: twentyFiveDaysOfLEANMassWeek4Loading} = useWorkout(
      6796,
      null,
      null,
      workoutsConfig,
  )

  const {data: setYourNewYearGoalsProduct, loading: setYourNewYearGoalsLoading} = useWorkout(
    6877,
    null,
    null,
    workoutsConfig,
  )

  const {data: newYearPrepProduct, loading: newYearPrepLoading} = useWorkout(
    6883,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionPhase1Product, loading: pilatesEvolutionPhase1Loading} = useWorkout(
    7120,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek1Product, loading: pilatesEvolutionWeek1Loading} = useWorkout(
    6920,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek2Product, loading: pilatesEvolutionWeek2Loading} = useWorkout(
    7014,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek3Product, loading: pilatesEvolutionWeek3Loading} = useWorkout(
    7125,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek4Product, loading: pilatesEvolutionWeek4Loading} = useWorkout(
    7147,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionPhase2Product, loading: pilatesEvolutionPhase2Loading} = useWorkout(
    7209,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek5Product, loading: pilatesEvolutionWeek5Loading} = useWorkout(
    7168,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek6Product, loading: pilatesEvolutionWeek6Loading} = useWorkout(
    7185,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesEvolutionWeek7Product, loading: pilatesEvolutionWeek7Loading} = useWorkout(
    7190,
    null,
    null,
    workoutsConfig,
  )
  const {data: pilatesEvolutionDay50Product, loading: pilatesEvolutionDay50Loading} = useWorkout(
      7198,
      null,
      null,
      workoutsConfig,
  )

  const {data: pilatesEvolutionWeek9Product, loading: pilatesEvolutionWeek9Loading} = useWorkout(
    7203,
    null,
    null,
    workoutsConfig,
  )

  const {data: bestOfLmpeWeek1Product, loading: bestOfLmpeWeek1Loading} = useWorkout(
    7237,
    null,
    null,
    workoutsConfig,
  )

  const {data: bestOfLmpeWeek2Product, loading: bestOfLmpeWeek2Loading} = useWorkout(
    7242,
    null,
    null,
    workoutsConfig,
  )

  const {data: bestOfLmpeProduct, loading: bestOfLmpeLoading} = useWorkout(
    7274,
    null,
    null,
    workoutsConfig,
  )

  const {data: weekOfWellnessProduct, loading: weekOfWellnessLoading} = useWorkout(
    7268,
    null,
    null,
    workoutsConfig,
  )

  const {data: matInspiredReformerPilatesProduct, loading: matInspiredReformerPilatesLoading} = useWorkout(
    7279,
    null,
    null,
    workoutsConfig,
  )

  const {data: ultimateStrengthChallengeProduct, loading: ultimateStrengthChallengeLoading} = useWorkout(
    7300,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesStrengthFusionProduct, loading: pilatesStrengthFusionLoading} = useWorkout(
    7349,
    null,
    null,
    workoutsConfig,
  )

  const {data: lowImpactFatBurnProduct, loading: lowImpactFatBurnLoading} = useWorkout(
    7427,
    null,
    null,
    workoutsConfig,
  )

  const {data: sunsOutBunsOutProduct, loading: sunsOutBunsOutLoading} = useWorkout(
    7437,
    null,
    null,
    workoutsConfig,
  )

  const {data: powerUpPilatesWeek1Product, loading: powerUpPilatesWeek1Loading} = useWorkout(
    7442,
    null,
    null,
    workoutsConfig,
  )

  const {data: powerUpPilatesWeek2Product, loading: powerUpPilatesWeek2Loading} = useWorkout(
    7471,
    null,
    null,
    workoutsConfig,
  )

  const {data: powerUpPilatesWeek3Product, loading: powerUpPilatesWeek3Loading} = useWorkout(
    7515,
    null,
    null,
    workoutsConfig,
  )

  const {data: summerSurprisePrepProduct, loading: summerSurprisePrepLoading} = useWorkout(
    7534,
    null,
    null,
    workoutsConfig,
  )

  const {data: summerSurprisePrepWeek1Product, loading: summerSurprisePrepWeek1Loading} = useWorkout(
    7492,
    null,
    null,
    workoutsConfig,
  )

  const {data: summerSurprisePrepw2Product, loading: summerSurprisePrepw2Loading} = useWorkout(
    7497,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptPhase1Product, loading: pilatesSummerSculptPhase1Loading} = useWorkout(
    7550,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek1Product, loading: pilatesSummerSculptWeek1Loading} = useWorkout(
    7528,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek2Product, loading: pilatesSummerSculptWeek2Loading} = useWorkout(
    7566,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek3Product, loading: pilatesSummerSculptWeek3Loading} = useWorkout(
    7598,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek4Product, loading: pilatesSummerSculptWeek4Loading} = useWorkout(
    7631,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptPhase2Product, loading: pilatesSummerSculptPhase2Loading} = useWorkout(
    7645,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek5Product, loading: pilatesSummerSculptWeek5Loading} = useWorkout(
    7637,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek6Product, loading: pilatesSummerSculptWeek6Loading} = useWorkout(
    7668,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek7Product, loading: pilatesSummerSculptWeek7Loading} = useWorkout(
    7686,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek8Product, loading: pilatesSummerSculptWeek8Loading} = useWorkout(
    7679,
    null,
    null,
    workoutsConfig,
  )

  const {data: pilatesSummerSculptWeek9Product, loading: pilatesSummerSculptWeek9Loading} = useWorkout(
      7727,
      null,
      null,
      workoutsConfig,
  )

  const {data: leanLifestyleProduct, loading: leanLifestyleLoading} = useWorkout(
    7736,
    null,
    null,
    workoutsConfig,
)

const {data: coreStrengthStabilityProduct, loading: coreStrengthStabilityLoading} = useWorkout(
  7766,
  null,
  null,
  workoutsConfig,
)

const {data: buildStrengthMonthWeek1Product, loading: buildStrengthMonthWeek1Loading} = useWorkout(
  7789,
  null,
  null,
  workoutsConfig,
)

const {data: buildStrengthMonthWeek2Product, loading: buildStrengthMonthWeek2Loading} = useWorkout(
  7843,
  null,
  null,
  workoutsConfig,
)

const {data: buildStrengthMonthWeek3Product, loading: buildStrengthMonthWeek3Loading} = useWorkout(
  7848,
  null,
  null,
  workoutsConfig,
)

const {data: buildStrengthMonthWeek4Product, loading: buildStrengthMonthWeek4Loading} = useWorkout(
  7876,
  null,
  null,
  workoutsConfig,
)

const {data: resetAndThriveChallengeProduct, loading: resetAndThriveChallengeLoading} = useWorkout(
  7898,
  null,
  null,
  workoutsConfig,
)

const {data: buildYourStengthMonthProduct, loading: buildYourStengthMonthLoading} = useWorkout(
  7919,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfLeanMethodProduct, loading: bestOfLeanMethodLoading} = useWorkout(
  7902,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfLeanMethodWeek1Product, loading: bestOfLeanMethodWeek1Loading} = useWorkout(
  7912,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfLeanMethodWeek2Product, loading: bestOfLeanMethodWeek2Loading} = useWorkout(
  7933,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfLeanMethodWeek3Product, loading: bestOfLeanMethodWeek3Loading} = useWorkout(
  7938,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfLeanMethodWeek4Product, loading: bestOfLeanMethodWeek4Loading} = useWorkout(
  7943,
  null,
  null,
  workoutsConfig,
)

const {data: ultimateStrengthChallenge2Product, loading: ultimateStrengthChallenge2Loading} = useWorkout(
  7998,
  null,
  null,
  workoutsConfig,
)

const {data: halloLeanHellWeek24Product, loading: halloLeanHellWeek24Loading} = useWorkout(
  8019,
  null,
  null,
  workoutsConfig,
)

const {data: twentyOneVol4Week1Product, loading: twentyOneVol4Week1Loading} = useWorkout(
  8044,
  null,
  null,
  workoutsConfig,
)

  const {data: twentyOneVol4Week2Product, loading: twentyOneVol4Week2Loading} = useWorkout(
      8065,
      null,
      null,
      workoutsConfig,
  )

  const {data: twentyOneVol4Week3Product, loading: twentyOneVol4Week3Loading} = useWorkout(
    8074,
    null,
    null,
    workoutsConfig,
)

const {data: twentyOneVol4Product, loading: twentyOneVol4Loading} = useWorkout(
  8104,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfSculptWeek1Product, loading: bestOfSculptWeek1Loading} = useWorkout(
  8035,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfSculptWeek2Product, loading: bestOfSculptWeek2Loading} = useWorkout(
  8114,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfSculptWeek3Product, loading: bestOfSculptWeek3Loading} = useWorkout(
  8120,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfSculptWeek4Product, loading: bestOfSculptWeek4Loading} = useWorkout(
  8174,
  null,
  null,
  workoutsConfig,
)

const {data: bestOfSculptStrengthProduct, loading: bestOfSculptStrengthLoading} = useWorkout(
  8193,
  null,
  null,
  workoutsConfig,
)

const {data: winterGlowUp2Product, loading: winterGlowUp2Loading} = useWorkout(
  8209,
  null,
  null,
  workoutsConfig,
)

const {data: newYearGoalsProduct, loading: newYearGoalsLoading} = useWorkout(
  8227,
  null,
  null,
  workoutsConfig,
)

const {data: levelUpPrepProduct, loading: levelUpPrepLoading} = useWorkout(
  8277,
  null,
  null,
  workoutsConfig,
)

const {data: leanMethodLevelUpProduct, loading: leanMethodLevelUpLoading} = useWorkout(
  8292,
  null,
  null,
  workoutsConfig,
)

const {data: leanMethodLevelUpW1Product, loading: leanMethodLevelUpW1Loading} = useWorkout(
  8307,
  null,
  null,
  workoutsConfig,
)

const {data: leanMethodLevelUpW2Product, loading: leanMethodLevelUpW2Loading} = useWorkout(
  8348,
  null,
  null,
  workoutsConfig,
)

  const {data: leanMethodLevelUpW3Product, loading: leanMethodLevelUpW3Loading} = useWorkout(
      8353,
      null,
      null,
      workoutsConfig,
  )

  const loading =
      leanMethodLevelUpW3Loading ||
      leanMethodLevelUpW2Loading ||
      leanMethodLevelUpW1Loading ||
      leanMethodLevelUpLoading ||
      levelUpPrepLoading ||
      newYearGoalsLoading ||
      winterGlowUp2Loading ||
      bestOfSculptStrengthLoading ||
      bestOfSculptWeek4Loading ||
      bestOfSculptWeek3Loading ||
      bestOfSculptWeek2Loading ||
      bestOfSculptWeek1Loading ||
      twentyOneVol4Loading ||
      twentyOneVol4Week3Loading ||
      twentyOneVol4Week2Loading ||
      twentyOneVol4Week1Loading ||
      halloLeanHellWeek24Loading ||
      ultimateStrengthChallenge2Loading ||
      bestOfLeanMethodWeek4Loading ||
      bestOfLeanMethodWeek3Loading ||
      bestOfLeanMethodWeek2Loading ||
      buildYourStengthMonthLoading ||
      bestOfLeanMethodLoading ||
      bestOfLeanMethodWeek1Loading ||
      resetAndThriveChallengeLoading ||
      buildStrengthMonthWeek4Loading ||
      buildStrengthMonthWeek3Loading ||
      buildStrengthMonthWeek2Loading ||
      buildStrengthMonthWeek1Loading ||
      coreStrengthStabilityLoading ||
      leanLifestyleLoading ||
      pilatesSummerSculptWeek9Loading ||
      pilatesSummerSculptWeek8Loading ||
      pilatesSummerSculptWeek7Loading ||
      pilatesSummerSculptWeek6Loading ||
      pilatesSummerSculptPhase2Loading ||
      pilatesSummerSculptWeek5Loading ||
      pilatesSummerSculptWeek4Loading ||
      pilatesSummerSculptWeek3Loading ||
      pilatesSummerSculptWeek2Loading ||
      pilatesSummerSculptWeek1Loading ||
      pilatesSummerSculptPhase1Loading ||
      summerSurprisePrepw2Loading ||
      powerUpPilatesWeek3Loading ||
      summerSurprisePrepLoading ||
      summerSurprisePrepWeek1Loading ||
      powerUpPilatesWeek2Loading ||
      powerUpPilatesWeek1Loading ||
      sunsOutBunsOutLoading ||
      lowImpactFatBurnLoading ||
      pilatesStrengthFusionLoading ||
      ultimateStrengthChallengeLoading ||
      matInspiredReformerPilatesLoading ||
      weekOfWellnessLoading ||
      bestOfLmpeWeek2Loading ||
      bestOfLmpeWeek1Loading ||
      bestOfLmpeLoading ||
      pilatesEvolutionWeek9Loading ||
      pilatesEvolutionDay50Loading ||
      pilatesEvolutionWeek7Loading ||
      pilatesEvolutionWeek6Loading ||
      pilatesEvolutionWeek5Loading ||
      pilatesEvolutionPhase2Loading ||
      pilatesEvolutionWeek4Loading ||
      pilatesEvolutionWeek3Loading ||
      pilatesEvolutionWeek2Loading ||
      pilatesEvolutionWeek1Loading ||
      pilatesEvolutionPhase1Loading ||
      newYearPrepLoading ||
      setYourNewYearGoalsLoading ||
      twentyFiveDaysOfLEANMassWeek4Loading ||
      twentyFiveDaysOfLEANMassWeek3Loading ||
      twentyFiveDaysOfLEANMassWeek2Loading ||
      twentyFiveDaysOfLEANMassWeek1Loading ||
      twentyFiveDaysOfLEANMassLoading ||
      pilatesBootcampChallengeLoading ||
      pilatesBootcampChallengeWeek2Loading ||
      pilatesBootcampChallengeWeek1Loading ||
      leanEmpoweredMethodPhase2Week8Loading ||
      halloleanHellWeekLoading ||
      leanEmpoweredMethodPhase2Week7Loading ||
      leanEmpoweredMethodPhase2Week6Loading ||
      leanEmpoweredMethodPhase2Week5Loading ||
      leanEmpoweredMethodPhase2Loading ||
      leanEmpoweredMethodPhase1Week4Loading ||
      leanEmpoweredMethodWeek3Loading ||
      leanEmpoweredMethodPhase1Week2Loading ||
      leanEmpoweredMethodPhase1Week1Loading ||
      leanEmpoweredMethodPhase1Loading ||
      absAndUpperBodyLoading ||
      hourGlassShredShapeLoading ||
      shredWithWeightsLoading ||
      shapeBurnPilatesLoading ||
      yourStrongestSelfLoading ||
      legsSculptFatBurnLoading ||
      fatBurnCoreGaloreLoading ||
      twentyOneVol3Week3Loading ||
      twentyOneVol3Week2Loading ||
      twentyOneVol3Week1Loading ||
      twentyOneVol3Loading ||
      leanSecondBirthdayBashLoading ||
      leanSummerMethodWeek8Loading ||
      leanSummerMethodWeek7Loading ||
      leanSummerMethodWeek6Loading ||
      leanSummerMethodWeek5Loading ||
      leanSummerMethodWeek4Loading ||
      leanSummerMethodWeek3Loading ||
      leanSummerMethodWeek2Loading ||
  leanSummerMethodWeek1Loading ||
      leanSummerMethodPhase2Loading ||
  leanSummerMethodPhase1Loading ||
    powerPilatesLoading ||
    ultimateWeekOfBattlesLoading ||
      hourglassSculptAndStrengthenLoading ||
    ohHiitLoading||
    sculptBurnAndShapeLoading ||
    upperBodyAbsAndShredLoading ||
    lowerBodyLoading ||
    winterGlowUpLoading ||
    recoverAndStrengthenPilatesLoading ||
    feelGoodFactory4WeeksLoading ||
    twentyOneVol24WeeksLoading ||
    greensAndGlow4WeeksLoading ||
    vacayGuide4WeeksLoading ||
    feelGoodSummer4WeeksLoading ||
    leanAndStrongTransformationVol24WeeksLoading ||
    leanAndStrongTransformationVol14WeeksLoading ||
    cardioGlowUp4WeeksLoading ||
    bestOfSculptMonthLoading ||
    leanMass2022RepackedLoading ||
    leanTransformationMethodWeek8Loading ||
    leanTransformationMethodWeek7Loading ||
    leanTransformationMethodWeek6Loading ||
    leanTransformationMethodWeek5Loading ||
    leanTransformationMethodWeek4Loading ||
    leanTransformationMethodWeek3Loading ||
    leanTransformationMethodWeek1Loading||
    leanTransformationMethodWeek2Loading ||
    leanTransformationMethodPhase1Loading ||
    leanTransformationMethodPhase2Loading ||
      leanMass2022Week2Loading ||
      leanMass2022Loading ||
      leanAndPowerfulLoading ||
      cardioGlowUpLoading ||
       busyGirlBlackFridayWeek2Loading ||
      busyGirlBlackFridayWeek1Loading ||
      busyGirlBlackFriday4WeeksLoading ||
      pilatesBodyLoading ||
    bestOfStrengthLoading ||
    halloLeanHellWeek22Loading ||
    halloLeanPrepLoading ||
    feelGoodFitFactoryWeek6Loading ||
    feelGoodFitFactoryWeek5Loading ||
    feelGoodFitFactoryWeek4Loading ||
    feelGoodFitFactoryWeek3Loading ||
    septChallengeWeek2Loading ||
    septChallengeWeek1Loading ||
    gimmeGummyMoreLoading ||
    twentyOneVol2Week3Loading ||
    twentyOneVol2Week2Loading ||
    twentyOneVol2Week1Loading ||
    glowWeek4Loading ||
    glowWeek3Loading ||
    glowWeek2Loading ||
    glowWeek1Loading ||
    vacayWeek2Loading ||
    vacayLoading ||
    birthdayLoading ||
    bestOfShredLoading ||
    bestOfSculptLoading ||
    feelGoodSummerWeek6Loading ||
    feelGoodSummerWeek5Loading ||
    feelGoodSummerWeek4Loading ||
    periodFriendlyLoading ||
    feelGoodSummerWeek3Loading ||
    feelGoodSummerWeek2Loading ||
    feelGoodSummerWeek1Loading ||
    // maryBraunWeek4Loading ||
    // maryBraunWeek3Loading ||
    preSummerBootcampLoading ||
    deloadPilatesBootCampLoading ||
    // maryBraunWeek2Loading ||
    // maryBraunWeek1Loading ||
    transformationV2Week6Loading ||
    transformationV2Week5Loading ||
    transformationV2Week4Loading ||
    transformationV2Week3Loading ||
    transformationV2Week2Loading ||
    transformationV2Loading ||
    turnItUpLoading ||
    galentineLoading ||
    week6Loading ||
    week5Loading ||
    week4Loading ||
    janWeek3Loading ||
    strongProductLoading ||
    janProductLoading ||
    pilatesProductLoading ||
    leanMasProductLoading ||
    strongCapableProductLoading ||
    cutStudentProductLoading ||
    halloLeanProductLoading ||
    twentyOneProductLoading ||
    smallerWaistWorkoutProductLoading ||
    hourglassWorkoutProductLoading ||
    bootyWorkoutProductLoading ||
    sabriWorkoutProductLoading ||
    howToBandsDataLoading ||
    nutritionLoading ||
    learnExercisesDataLoading ||
    physioDataLoading ||
    profileUserDataLoading ||
    currentGuideProductLoading ||
    challengesDataLoading;

  /*
   * "Classes" is an standardized abstract concept so that we can consistently
   * render workout and course classes as a single class component for the user
   * */

  /*
        TODO: This does not  currently work as completed sessions are now saved in user data
     */
  // const {
  //     loading: completedSessionsLoading,
  //     data: completedSessions,
  //     totalCompletedSessions
  // } = useCompletedSessions();

  // const isCompletedSession = (classItem) => {
  //     const completedSessionIDs = completedSessions?.map(({session_id}) => parseInt(session_id));
  //     return completedSessionIDs.indexOf(parseInt(classItem?.sessionId)) > -1
  // }

  // Hide certain challenges based on their releaseTimestamp metadata key
  challengesData.sections = challengesData.sections?.map((section) => ({
    ...section,
    lessons: section?.lessons.filter(({ assets }) => {
      const releaseTimestamp = get(JSON.parse(get(assets, "[0].meta_data", {})), "releaseTimestamp");
      return releaseTimestamp && moment().diff(moment(releaseTimestamp)) > 0;
    }),
  }));

  const classes = [
    ...((guideProduct &&
      transformWorkoutProductToClasses(guideProduct, {
        isCurrentGuide: true,
      })) ||
      []),
    ...transformWorkoutProductToClasses(leanMethodLevelUpW3Product),
    ...transformWorkoutProductToClasses(leanMethodLevelUpW2Product),
    ...transformWorkoutProductToClasses(leanMethodLevelUpW1Product),
    ...transformWorkoutProductToClasses(leanMethodLevelUpProduct),
    ...transformWorkoutProductToClasses(levelUpPrepProduct),
    ...transformWorkoutProductToClasses(newYearGoalsProduct),
    ...transformWorkoutProductToClasses(winterGlowUp2Product),
    ...transformWorkoutProductToClasses(bestOfSculptStrengthProduct),
    ...transformWorkoutProductToClasses(bestOfSculptWeek4Product),
    ...transformWorkoutProductToClasses(bestOfSculptWeek3Product),
    ...transformWorkoutProductToClasses(bestOfSculptWeek2Product),
    ...transformWorkoutProductToClasses(bestOfSculptWeek1Product),
    ...transformWorkoutProductToClasses(twentyOneVol4Week3Product),
    ...transformWorkoutProductToClasses(twentyOneVol4Product),
    ...transformWorkoutProductToClasses(twentyOneVol4Week2Product),
    ...transformWorkoutProductToClasses(twentyOneVol4Week1Product),
    ...transformWorkoutProductToClasses(halloLeanHellWeek24Product),
    ...transformWorkoutProductToClasses(ultimateStrengthChallenge2Product),
    ...transformWorkoutProductToClasses(bestOfLeanMethodWeek4Product),
    ...transformWorkoutProductToClasses(bestOfLeanMethodWeek3Product),
    ...transformWorkoutProductToClasses(bestOfLeanMethodWeek2Product),
    ...transformWorkoutProductToClasses(buildYourStengthMonthProduct),
    ...transformWorkoutProductToClasses(bestOfLeanMethodProduct),
    ...transformWorkoutProductToClasses(bestOfLeanMethodWeek1Product),
    ...transformWorkoutProductToClasses(resetAndThriveChallengeProduct),
    ...transformWorkoutProductToClasses(buildStrengthMonthWeek4Product),
    ...transformWorkoutProductToClasses(buildStrengthMonthWeek3Product),
    ...transformWorkoutProductToClasses(buildStrengthMonthWeek2Product),
    ...transformWorkoutProductToClasses(buildStrengthMonthWeek1Product),
    ...transformWorkoutProductToClasses(coreStrengthStabilityProduct),
    ...transformWorkoutProductToClasses(leanLifestyleProduct),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek9Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek8Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek7Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek6Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptPhase2Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek5Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek4Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek3Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek2Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptWeek1Product),
    ...transformWorkoutProductToClasses(pilatesSummerSculptPhase1Product),
    ...transformWorkoutProductToClasses(summerSurprisePrepw2Product),
    ...transformWorkoutProductToClasses(powerUpPilatesWeek3Product),
    ...transformWorkoutProductToClasses(summerSurprisePrepProduct),
    ...transformWorkoutProductToClasses(summerSurprisePrepWeek1Product),
    ...transformWorkoutProductToClasses(powerUpPilatesWeek2Product),
    ...transformWorkoutProductToClasses(powerUpPilatesWeek1Product),
    ...transformWorkoutProductToClasses(sunsOutBunsOutProduct),
    ...transformWorkoutProductToClasses(lowImpactFatBurnProduct),
    ...transformWorkoutProductToClasses(pilatesStrengthFusionProduct),
    ...transformWorkoutProductToClasses(ultimateStrengthChallengeProduct),
    ...transformWorkoutProductToClasses(matInspiredReformerPilatesProduct),
    ...transformWorkoutProductToClasses(weekOfWellnessProduct),
    ...transformWorkoutProductToClasses(bestOfLmpeWeek2Product),
    ...transformWorkoutProductToClasses(bestOfLmpeWeek1Product),
    ...transformWorkoutProductToClasses(bestOfLmpeProduct),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek9Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionDay50Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek7Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek6Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek5Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionPhase2Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek4Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek3Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek2Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionWeek1Product),
    ...transformWorkoutProductToClasses(pilatesEvolutionPhase1Product),
    ...transformWorkoutProductToClasses(newYearPrepProduct),
    ...transformWorkoutProductToClasses(setYourNewYearGoalsProduct),
    ...transformWorkoutProductToClasses(twentyFiveDaysOfLEANMassWeek4Product),
    ...transformWorkoutProductToClasses(twentyFiveDaysOfLEANMassWeek3Product),
    ...transformWorkoutProductToClasses(twentyFiveDaysOfLEANMassWeek2Product),
    ...transformWorkoutProductToClasses(twentyFiveDaysOfLEANMassWeek1Product),
    ...transformWorkoutProductToClasses(twentyFiveDaysOfLEANMassProduct),
    ...transformWorkoutProductToClasses(pilatesBootcampChallengeProduct),
    ...transformWorkoutProductToClasses(pilatesBootcampChallengeWeek2Product),
    ...transformWorkoutProductToClasses(pilatesBootcampChallengeWeek1Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase2Week8Product),
    ...transformWorkoutProductToClasses(halloleanHellWeekProduct),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase2Week7Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase2Week6Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase2Week5Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase2Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase1Week4Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodWeek3Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase1Week2Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase1Week1Product),
    ...transformWorkoutProductToClasses(leanEmpoweredMethodPhase1Product),
    ...transformWorkoutProductToClasses(absAndUpperBodyProduct),
    ...transformWorkoutProductToClasses(hourGlassShredShapeProduct),
    ...transformWorkoutProductToClasses(shredWithWeightsProduct),
    ...transformWorkoutProductToClasses(shapeBurnPilatesProduct),
    ...transformWorkoutProductToClasses(yourStrongestSelfProduct),
    ...transformWorkoutProductToClasses(legsSculptFatBurnProduct),
    ...transformWorkoutProductToClasses(fatBurnCoreGaloreProduct),
    ...transformWorkoutProductToClasses(twentyOneVol3Week3Product),
    ...transformWorkoutProductToClasses(twentyOneVol3Week2Product),
    ...transformWorkoutProductToClasses(twentyOneVol3Week1Product),
    ...transformWorkoutProductToClasses(twentyOneVol3Product),
    ...transformWorkoutProductToClasses(leanSecondBirthdayBashProduct),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek8Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek7Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek6Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek5Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek4Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek3Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek2Product),
    ...transformWorkoutProductToClasses(leanSummerMethodWeek1Product),
    ...transformWorkoutProductToClasses(leanSummerMethodPhase2Product),
    ...transformWorkoutProductToClasses(leanSummerMethodPhase1Product),
    ...transformWorkoutProductToClasses(powerPilatesProduct),
    ...transformWorkoutProductToClasses(ultimateWeekOfBattlesProduct),
    ...transformWorkoutProductToClasses(hourglassSculptAndStrengthenProduct),
    ...transformWorkoutProductToClasses(ohHiitProduct),
    ...transformWorkoutProductToClasses(sculptBurnAndShapeProduct),
    ...transformWorkoutProductToClasses(upperBodyAbsAndShredProduct),
    ...transformWorkoutProductToClasses(lowerBodyProduct),
    ...transformWorkoutProductToClasses(winterGlowUpProduct),
    ...transformWorkoutProductToClasses(recoverAndStrengthenPilatesProduct),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek8Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek7Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek6Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek5Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek4Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek3Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek2Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodWeek1Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodPhase1Product),
    ...transformWorkoutProductToClasses(leanTransformationMethodPhase2Product),
    ...transformWorkoutProductToClasses(bestOfSculptMonthProduct),
    ...transformWorkoutProductToClasses(leanMass2022RepackedProduct),
    ...transformWorkoutProductToClasses(leanMass2022Week2Product),
    ...transformWorkoutProductToClasses(leanMass2022Product),
    ...transformWorkoutProductToClasses(leanAndPowerfulProduct),
    ...transformWorkoutProductToClasses(cardioGlowUp4WeeksProduct),
    ...transformWorkoutProductToClasses(cardioGlowUpProduct),
      ...transformWorkoutProductToClasses(busyGirlBlackFriday4WeeksProduct),
    ...transformWorkoutProductToClasses(busyGirlBlackFridayWeek2Product),
    ...transformWorkoutProductToClasses(busyGirlBlackFridayWeek1Product),
    ...transformWorkoutProductToClasses(pilatesBodyProduct),
    ...transformWorkoutProductToClasses(bestOfStrengthProduct),
    ...transformWorkoutProductToClasses(halloLeanHellWeek22Product),
    ...transformWorkoutProductToClasses(halloLeanPrepProduct),
    ...transformWorkoutProductToClasses(feelGoodFactory4WeeksProduct),
    ...transformWorkoutProductToClasses(feelGoodFitFactoryWeek6Product),
    ...transformWorkoutProductToClasses(feelGoodFitFactoryWeek5Product),
    ...transformWorkoutProductToClasses(feelGoodFitFactoryWeek4Product),
    ...transformWorkoutProductToClasses(feelGoodFitFactoryWeek3Product),
    ...transformWorkoutProductToClasses(septChallengeWeek2Product),
    ...transformWorkoutProductToClasses(septChallengeWeek1Product),
    ...transformWorkoutProductToClasses(gimmeGummyMoreProduct),
    ...transformWorkoutProductToClasses(twentyOneVol24WeeksProduct),
    ...transformWorkoutProductToClasses(twentyOneVol2Week3Product),
    ...transformWorkoutProductToClasses(twentyOneVol2Week2Product),
    ...transformWorkoutProductToClasses(twentyOneVol2Week1Product),
    ...transformWorkoutProductToClasses(greensAndGlow4WeeksProduct),
    ...transformWorkoutProductToClasses(glowWeek4Product),
    ...transformWorkoutProductToClasses(glowWeek3Product),
    ...transformWorkoutProductToClasses(glowWeek2Product),
    ...transformWorkoutProductToClasses(glowWeek1Product),
    ...transformWorkoutProductToClasses(vacayGuide4WeeksProduct),
    ...transformWorkoutProductToClasses(vacayWeek2Product),
    ...transformWorkoutProductToClasses(vacayProduct),
    ...transformWorkoutProductToClasses(birthdayProduct),
    ...transformWorkoutProductToClasses(bestOfShredProduct),
    ...transformWorkoutProductToClasses(bestOfSculptProduct),
    ...transformWorkoutProductToClasses(feelGoodSummer4WeeksProduct),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek6Product),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek5Product),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek4Product),
    ...transformWorkoutProductToClasses(periodFriendlyProduct),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek3Product),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek2Product),
    ...transformWorkoutProductToClasses(feelGoodSummerWeek1Product),
    // ...transformWorkoutProductToClasses(maryBraunWeek4Product),
    // ...transformWorkoutProductToClasses(maryBraunWeek3Product),
    ...transformWorkoutProductToClasses(preSummerBootcampProduct),
    ...transformWorkoutProductToClasses(deloadPilatesBootCampProduct),
    // ...transformWorkoutProductToClasses(maryBraunWeek2Product),
    // ...transformWorkoutProductToClasses(maryBraunWeek1Product),
    ...transformWorkoutProductToClasses(leanAndStrongTransformationVol24WeeksProduct),
    ...transformWorkoutProductToClasses(transformationV2Week6Product),
    ...transformWorkoutProductToClasses(transformationV2Week5Product),
    ...transformWorkoutProductToClasses(transformationV2Week4Product),
    ...transformWorkoutProductToClasses(transformationV2Week3Product),
    ...transformWorkoutProductToClasses(transformationV2Wee2Product),
    ...transformWorkoutProductToClasses(transformationV2Product),
    ...transformWorkoutProductToClasses(turnItUpProduct),
    ...transformWorkoutProductToClasses(leanAndStrongTransformationVol14WeeksProduct),
    ...transformWorkoutProductToClasses(galentineProduct),
    ...transformWorkoutProductToClasses(week6Product),
    ...transformWorkoutProductToClasses(week5Product),
    ...transformWorkoutProductToClasses(week4Product),
    ...transformWorkoutProductToClasses(janWeek3Product),
    ...transformWorkoutProductToClasses(strongWorkoutProduct),
    ...transformWorkoutProductToClasses(janWorkoutProduct),
    ...transformWorkoutProductToClasses(pilatesWorkoutProduct),
    ...transformWorkoutProductToClasses(bootyWorkoutProduct),
    ...transformWorkoutProductToClasses(smallerWaistWorkoutProduct),
    ...transformWorkoutProductToClasses(hourglassWorkoutProduct),
    ...transformWorkoutProductToClasses(sabriWorkoutProduct),
    ...transformWorkoutProductToClasses(twentyOneWorkoutProduct),
    ...transformWorkoutProductToClasses(halloLeanWorkoutProduct),
    ...transformWorkoutProductToClasses(leanMasWorkoutProduct),
    ...transformWorkoutProductToClasses(cutStudentWorkoutProduct),
    ...transformWorkoutProductToClasses(strongCapableWorkoutProduct),
    ...transformCourseToClasses(learnExercisesData),
    ...transformCourseToClasses(howToBandsData),
    ...transformCourseToClasses(physioData),
    ...transformCourseToClasses(nutritionData),
    ...transformCourseToClasses(challengesData, {
      edits: {
        lockType: "challenge",
        lockId: (v, i) => !get(JSON.parse(get(v, "assets[0].meta_data", {})), "free"),
      },
    }),
  ];

  const guides = [
      leanMethodLevelUpW3Product,
    leanMethodLevelUpW2Product,
    leanMethodLevelUpW1Product,
    leanMethodLevelUpProduct,
    levelUpPrepProduct,
    newYearGoalsProduct,
    winterGlowUp2Product,
    bestOfSculptStrengthProduct,
    bestOfSculptWeek4Product,
    bestOfSculptWeek3Product,
    bestOfSculptWeek2Product,
    bestOfSculptWeek1Product,
    twentyOneVol4Product,
    twentyOneVol4Week3Product,
    twentyOneVol4Week2Product,
    twentyOneVol4Week1Product,
    halloLeanHellWeek24Product,
    ultimateStrengthChallenge2Product,
    bestOfLeanMethodWeek4Product,
    bestOfLeanMethodWeek3Product,
    bestOfLeanMethodWeek2Product,
    bestOfLeanMethodWeek1Product,
    bestOfLeanMethodProduct,
    buildYourStengthMonthProduct,
    resetAndThriveChallengeProduct,
    buildStrengthMonthWeek4Product,
    buildStrengthMonthWeek3Product,
    buildStrengthMonthWeek2Product,
    buildStrengthMonthWeek1Product,
    coreStrengthStabilityProduct,
    leanLifestyleProduct,
    pilatesSummerSculptWeek9Product,
    pilatesSummerSculptWeek8Product,
    pilatesSummerSculptWeek7Product,
    pilatesSummerSculptWeek6Product,
    pilatesSummerSculptWeek5Product,
    pilatesSummerSculptPhase2Product,
    pilatesSummerSculptWeek4Product,
    pilatesSummerSculptWeek3Product,
    pilatesSummerSculptWeek2Product,
    pilatesSummerSculptWeek1Product,
    pilatesSummerSculptPhase1Product,
    summerSurprisePrepw2Product,
    summerSurprisePrepWeek1Product,
    summerSurprisePrepProduct,
    powerUpPilatesWeek3Product,
    powerUpPilatesWeek2Product,
    powerUpPilatesWeek1Product,
    sunsOutBunsOutProduct,
    lowImpactFatBurnProduct,
    pilatesStrengthFusionProduct,
    ultimateStrengthChallengeProduct,
    matInspiredReformerPilatesProduct,
    weekOfWellnessProduct,
    bestOfLmpeWeek2Product,
    bestOfLmpeWeek1Product,
    bestOfLmpeProduct,
    pilatesEvolutionWeek9Product,
    pilatesEvolutionDay50Product,
    pilatesEvolutionWeek7Product,
    pilatesEvolutionWeek6Product,
    pilatesEvolutionWeek5Product,
    pilatesEvolutionPhase2Product,
    pilatesEvolutionWeek4Product,
    pilatesEvolutionWeek3Product,
    pilatesEvolutionWeek2Product,
    pilatesEvolutionWeek1Product,
    pilatesEvolutionPhase1Product,
    newYearPrepProduct,
    setYourNewYearGoalsProduct,
    twentyFiveDaysOfLEANMassWeek4Product,
    twentyFiveDaysOfLEANMassWeek3Product,
    twentyFiveDaysOfLEANMassWeek2Product,
    twentyFiveDaysOfLEANMassWeek1Product,
    twentyFiveDaysOfLEANMassProduct,
    pilatesBootcampChallengeProduct,
    pilatesBootcampChallengeWeek2Product,
    pilatesBootcampChallengeWeek1Product,
    leanEmpoweredMethodPhase2Week8Product,
    halloleanHellWeekProduct,
    leanEmpoweredMethodPhase2Week7Product,
    leanEmpoweredMethodPhase2Week6Product,
    leanEmpoweredMethodPhase2Week5Product,
    leanEmpoweredMethodPhase2Product,
    leanEmpoweredMethodPhase1Week4Product,
    leanEmpoweredMethodWeek3Product,
    leanEmpoweredMethodPhase1Week2Product,
    leanEmpoweredMethodPhase1Week1Product,
    leanEmpoweredMethodPhase1Product,
    absAndUpperBodyProduct,
    hourGlassShredShapeProduct,
    shredWithWeightsProduct,
    shapeBurnPilatesProduct,
    yourStrongestSelfProduct,
    legsSculptFatBurnProduct,
    fatBurnCoreGaloreProduct,
    twentyOneVol3Week3Product,
    twentyOneVol3Week2Product,
    twentyOneVol3Week1Product,
    twentyOneVol3Product,
    leanSecondBirthdayBashProduct,
    leanSummerMethodWeek8Product,
    leanSummerMethodWeek7Product,
    leanSummerMethodWeek6Product,
    leanSummerMethodWeek5Product,
    leanSummerMethodWeek4Product,
    leanSummerMethodWeek3Product,
    leanSummerMethodWeek2Product,
    leanSummerMethodWeek1Product,
    leanSummerMethodPhase2Product,
    leanSummerMethodPhase1Product,
    powerPilatesProduct,
    ultimateWeekOfBattlesProduct,
    hourglassSculptAndStrengthenProduct,
    ohHiitProduct,
    sculptBurnAndShapeProduct,
    upperBodyAbsAndShredProduct,
    lowerBodyProduct,
    winterGlowUpProduct,
    recoverAndStrengthenPilatesProduct,
    leanTransformationMethodWeek8Product,
    leanTransformationMethodWeek7Product,
    leanTransformationMethodWeek6Product,
    leanTransformationMethodWeek5Product,
    leanTransformationMethodWeek4Product,
    leanTransformationMethodWeek3Product,
    leanTransformationMethodWeek2Product,
    leanTransformationMethodWeek1Product,
    leanTransformationMethodPhase2Product,
    leanTransformationMethodPhase1Product,
    bestOfSculptMonthProduct,
    leanMass2022RepackedProduct,
    leanMass2022Week2Product,
    leanMass2022Product,
    leanAndPowerfulProduct,
    cardioGlowUp4WeeksProduct,
    cardioGlowUpProduct,
    busyGirlBlackFriday4WeeksProduct,
    busyGirlBlackFridayWeek2Product,
    busyGirlBlackFridayWeek1Product,
    pilatesBodyProduct,
    bestOfStrengthProduct,
    halloLeanHellWeek22Product,
    halloLeanPrepProduct,
    feelGoodFactory4WeeksProduct,
    feelGoodFitFactoryWeek6Product,
    feelGoodFitFactoryWeek5Product,
    feelGoodFitFactoryWeek4Product,
    feelGoodFitFactoryWeek3Product,
    septChallengeWeek2Product,
    septChallengeWeek1Product,
    gimmeGummyMoreProduct,
    twentyOneVol24WeeksProduct,
    twentyOneVol2Week3Product,
    twentyOneVol2Week2Product,
    twentyOneVol2Week1Product,
    greensAndGlow4WeeksProduct,
    glowWeek4Product,
    glowWeek3Product,
    glowWeek2Product,
    glowWeek1Product,
    vacayGuide4WeeksProduct,
    vacayWeek2Product,
    vacayProduct,
    birthdayProduct,
    bestOfShredProduct,
    bestOfSculptProduct,
    feelGoodSummer4WeeksProduct,
    feelGoodSummerWeek6Product,
    feelGoodSummerWeek5Product,
    feelGoodSummerWeek4Product,
    periodFriendlyProduct,
    feelGoodSummerWeek3Product,
    feelGoodSummerWeek2Product,
    feelGoodSummerWeek1Product,
    // maryBraunWeek4Product,
    // maryBraunWeek3Product,
    preSummerBootcampProduct,
    deloadPilatesBootCampProduct,
    // maryBraunWeek2Product,
    // maryBraunWeek1Product,
    leanAndStrongTransformationVol24WeeksProduct,
    transformationV2Week6Product,
    transformationV2Week5Product,
    transformationV2Week4Product,
    transformationV2Week3Product,
    transformationV2Wee2Product,
    transformationV2Product,
    turnItUpProduct,
    leanAndStrongTransformationVol14WeeksProduct,
    galentineProduct,
    week6Product,
    week5Product,
    week4Product,
    janWeek3Product,
    strongWorkoutProduct,
    janWorkoutProduct,
    pilatesWorkoutProduct,
    leanMasWorkoutProduct,
    smallerWaistWorkoutProduct,
    bootyWorkoutProduct,
    hourglassWorkoutProduct,
    sabriWorkoutProduct,
    twentyOneWorkoutProduct,
    halloLeanWorkoutProduct,
    cutStudentWorkoutProduct,
    strongCapableWorkoutProduct,
  ];

  // console.log("GUIDES ------>", guides);

  const featuredClasses = classes?.filter((classObj) => classObj?.asset?.meta_data?.featured);

  // Grouping by subCategory, if any
  const groupBySubCategory = (toGroup) =>
    merge.apply(null, [
      {},
      ...keys(toGroup)?.map((key) => ({
        [key]: groupBy(toGroup[key], "subCategory"),
      })),
    ]) || [];

  const [allClasses, setAllClasses] = useState([]);
  const [allFeaturedClasses, setAllFeaturedClasses] = useState([]);

  useEffect(() => {
    if (!loading) {
      // Filtering out duplicates as many classes are reused
      const flatClasses = uniqBy(
        classes,
        (classData) => `${classData?.asset?.sproutId || classData?.asset?.id} - Current Guide: ${classData?.isCurrentGuide}`
      );
      const cleanedFeaturedClasses = uniqBy(
        featuredClasses,
        (classData) => `${classData?.asset?.sproutId} - Current Guide: ${classData?.isCurrentGuide}`
      );

      // const enhancedFlatClasses = flatClasses.map(classItem => ({
      //     ...classItem,
      //     isCompletedSession: isCompletedSession(classItem)
      // }))
      //
      // const enhancedFeaturedClasses = flatClasses.map(classItem => ({
      //     ...classItem,
      //     isCompletedSession: isCompletedSession(classItem)
      // }))

      // Grouping by course
      const groupedClasses = groupBy(flatClasses, "courseTitle");
      // Grouping by course with only featured classes
      const groupedFeaturedClasses = groupBy(cleanedFeaturedClasses, "courseTitle");

      const groupedClassesBySubCategory = groupBySubCategory(groupedClasses);
      const groupedFeaturedClassesBySubCategory = groupBySubCategory(groupedFeaturedClasses);

      setAllClasses(groupedClassesBySubCategory);
      setAllFeaturedClasses(groupedFeaturedClassesBySubCategory);
    }
  }, [loading]);

  const [searching, setSearching] = useState(false);
  const searchAllClasses = useCallback(
    (searchQuery) => {
      // Using FUSE.js to search all classes
      // TODO: Improve searching by adding filters/sort options and preferably powered by the backend, etc.
      setSearching(true);
      let filteredAllClasses = {};
      keys(allClasses)?.forEach((key) => {
        const courseClasses = flatten(values(allClasses?.[key]));
        const fuse = new Fuse(courseClasses || [], {
          isCaseSensitive: false,
          includeScore: true,
          shouldSort: true,
          minMatchCharLength: 3,
          threshold: 0.6,
          useExtendedSearch: true,
          keys: ["classTitle", "asset.description"],
        });
        const results = fuse?.search(searchQuery)?.map(({ item }) => item) || [];
        filteredAllClasses[key] = uniqBy(results, "asset.sproutId");
      });
      setSearching(false);

      return filteredAllClasses;
    },
    [allClasses]
  );

  /*
    * `classFilters` example structure = [
        [
            { label: WARM_UPS, filter: { workoutType: 'warmUp' } },
            { label: ACTIVATIONS, filter: { workoutType: 'activation' } },
            { label: OTHERS_IN_SESSION, filter: {sessionId: '50000' }}
        ]

        NB: `label` key is optional and NOT required for filtering

      `source` is which dataset is filtered, or it defaults to all classes
    * */
  const getFilteredClasses = useCallback(
    ({ config: { classFilters, source, includeCurrentGuide = false, includeRestDays = false } }) => {
      const filters = [...classFilters];
      let flatClasses = flattenDeep(values(values(source || allClasses))?.map(values));
      flatClasses = orderBy(flatClasses, [(classItem) => classItem?.subCategory?.toLowerCase(), "dayIndex"], ["asc"]);

      let resultFlatClasses = [];

      // console.log("classFilters: ", classFilters);

      filters.forEach((classFilter) => {
        const filteredData = flatClasses.filter(({ asset, productID, sessionId }) => {
          let filterResult = false;
          if (!!classFilter?.filter?.workoutType) {
            filterResult = asset?.meta_data?.workoutType?.indexOf(classFilter?.filter?.workoutType) > -1;
          }

          if (!!classFilter?.filter?.courseProductId) {
            filterResult = parseInt(productID) === parseInt(classFilter?.filter?.courseProductId);
          }

          if (!!classFilter?.filter?.sessionId) {
            filterResult = parseInt(sessionId) === parseInt(classFilter?.filter?.sessionId);
          }
          return filterResult;
        });
        resultFlatClasses.push(filteredData);
      });

      resultFlatClasses = flatten(resultFlatClasses);
      // resultFlatClasses = !!source ? resultFlatClasses : flatClasses; // This essentially reverts all filtering - leaving here incase this intentional in some case but seems incorrect

      if (!includeRestDays) {
        resultFlatClasses = resultFlatClasses?.filter((classItem) => !isRestDay(classItem));
      }

      if (!includeCurrentGuide) {
        resultFlatClasses = resultFlatClasses?.filter(({ isCurrentGuide }) => !isCurrentGuide);
        resultFlatClasses = orderBy(resultFlatClasses, "isCurrentGuide");
      }
      const groupedClasses = groupBy(resultFlatClasses, "courseTitle");
      return groupedClasses;
    },
    [allClasses]
  );

  // assetId is either session_exercise_id for workout based classes (`workout` type product)
  // OR asset_id from actual products of the `course` type
  const getClassByAssetID = (assetId) => {
    if (!classes?.length) return;

    return classes?.find(({ asset }) => parseInt(asset?.id) === parseInt(assetId));
  };

  return (
    <ContentManagerContext.Provider
      value={{
        currentGuideProductID,
        allClasses,
        allFeaturedClasses,
        searchAllClasses,
        getClassByAssetID,
        searching,
        loading,
        guides,
        getFilteredClasses,
        userWorkoutFrequency,
        // totalCompletedSessions
      }}
    >
      {children}
    </ContentManagerContext.Provider>
  );
};

export default ContentManagerProvider;
