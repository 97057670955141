import React from "react";
import useHasSubscription from "../hooks/useHasSubscription";
import SUBSCRIPTION_RULES from "../consts/SUBSCRIPTION_RULES";

const env = {
  vendorUuid: "a9ee763f-e6db-43cb-81a4-26e4bd0a573e",
  resetPasswordRedirectUri: "https://leanwithlilly.com/reset-password/?code=",
};

export const PHYSIO_COURSE_PRODUCT_ID = 3219;
export const LEARN_EXERCISES_COURSE_PRODUCT_ID = 3220; // also so known as the paid how-to's
export const NUTRITION_COURSE_PRODUCT_ID = 3221;
export const FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID = 3222;
export const CHALLENGES_PRODUCT_ID = 3558;

const GUIDE_MAP = {
  3: {
    booty: 3095,
    sabri: 3389,
    hourglass: 3232,
    smallerWaistGuide: 3100,
    janGuide: 4151,
    strongGuide: 4189,
    janWeek3: 4204,
    week4: 4237,
    week5: 4260,
    week6: 4267,
    leanAndStrongTransformationVol14Weeks: 4531,
    galentine: 4273,
    turnItUp: 4280,
    transformationV2: 4307,
    transformationV2_week2: 4313,
    transformationV2Week3: 4324,
    transformationV2Week4: 4358,
    transformationV2Week5: 4367,
    transformationV2Week6: 4420,
    leanAndStrongTransformationVol24Weeks: 4536,
    // maryBraunWeek1: 4457,
    // maryBraunWeek2: 4474,
    deloadPilatesBootCamp: 4437,
    preSummerBootcamp: 4469,
    // maryBraunWeek3: 4525,
    // maryBraunWeek4: 4530,
    feelGoodSummerWeek1: 4550,
    feelGoodSummerWeek2: 4560,
    feelGoodSummerWeek3: 4575,
    periodFriendly: 4570,
    feelGoodSummerWeek4: 4593,
    feelGoodSummerWeek5: 4601,
    feelGoodSummerWeek6: 4614,
    feelGoodSummer4Weeks: 4681,
    bestOfSculpt: 4652,
    bestOfShred: 4658,
    birthday:4695,
    vacay: 4700,
    vacayWeek2: 4730,
    vacayGuide4Weeks: 4842,
    glowWeek1:4743,
    glowWeek2:4757,
    glowWeek3:4785,
    glowWeek4:4790,
    greensAndGlow4Weeks: 4884,
    twentyOneVol2Week1: 4815,
    twentyOneVol2Week2: 4871,
    twentyOneVol2Week3: 4903,
    twentyOneVol24Weeks: 4995,
    gimmeGummyMore: 4924,
    septChallengeWeek1:4934,
    septChallengeWeek2:4943,
    feelGoodFitFactoryWeek3: 5031,
    feelGoodFitFactoryWeek4: 5062,
    feelGoodFitFactoryWeek5: 5084,
    feelGoodFitFactoryWeek6: 5098,
    feelGoodFitFactory4Weeks: 5141,
    halloLeanPrep: 5122,
    halloLeanHellWeek22: 5131,
    bestOfStrength:5156,
    pilatesBody: 5191,
    busyGirlBlackFridayWeek1: 5229,
    busyGirlBlackFridayWeek2: 5246,
    busyGirlBlackFriday4Weeks: 5337,
    cardioGlowUp: 5276,
    cardioGlowUp4Weeks: 5351,
    leanAndPowerful: 5285,
    leanMass2022: 5305,
    leanMass2022Week2: 5332,
    leanMass2022Repacked: 5478,
    bestOfSculptMonth: 5358,
    leanTransformationMethodPhase1: 5517,
    leanTransformationMethodPhase2: 5612,
    leanTransformationMethodWeek1: 5359,
    leanTransformationMethodWeek2: 5461,
    leanTransformationMethodWeek3: 5466,
    leanTransformationMethodWeek4: 5471,
    leanTransformationMethodWeek5: 5539,
    leanTransformationMethodWeek6: 5544,
    leanTransformationMethodWeek7: 5549,
    leanTransformationMethodWeek8: 5554,
    recoverAndStrengthenPilates: 5633,
    winterGlowUp: 5690,
    lowerBody: 5698,
    upperBodyAndAbsShred: 5717,
    sculptBurnAndShape: 5743,
    ohhiit:5770,
    hourglassSculptAndStrengthen: 5788,
    ultimateWeekOfBattles: 5807,
    powerPilates: 5823,
    leanSummerMethodPhase1: 5889,
    leanSummerMethodPhase2: 5981,
    leanSummerMethodWeek1: 5831,
    leanSummerMethodWeek2: 5850,
    leanSummerMethodWeek3: 5901,
    leanSummerMethodWeek4: 5906,
    leanSummerMethodWeek5: 5934,
    leanSummerMethodWeek6: 5939,
    leanSummerMethodWeek7: 5944,
    leanSummerMethodWeek8: 5949,
    leanSecondBirthdayBash: 6052,
    twentyOneVol3: 6161,
    twentyOneVol3Week1: 6099,
    twentyOneVol3Week2: 6136,
    twentyOneVol3Week3: 6150,
    fatBurnCoreGalore: 6181,
    legsSculptAndFatBurn: 6277,
    yourStrongestSelf: 6296,
    shapeBurnPilates: 6305,
    shredWithWeights: 6337,
    hourGlassShredShape: 6354,
    absAndUpperBody: 6363,
    leanEmpoweredMethodPhase1: 6380,
    leanEmpoweredMethodPhase1Week1: 6446,
    leanEmpoweredMethodWeek3: 6474,
    leanEmpoweredMethodPhase1Week2: 6469,
    leanEmpoweredMethodPhase1Week4: 6479,
    leanEmpoweredMethodPhase2Week5: 6580,
    leanEmpoweredMethodPhase2: 6617,
    leanEmpoweredMethodPhase2Week6: 6592,
    leanEmpoweredMethodPhase2Week7: 6604,
    halloleanHellWeek: 6627,
    leanEmpoweredMethodPhase2Week8: 6612,
    pilatesBootcampChallengeWeek1: 6743,
    pilatesBootcampChallengeWeek2: 6748,
    pilatesBootcampChallenge: 6808,
    twentyFiveDaysOfLEANMass: 6835,
    twentyFiveDaysOfLEANMassWeek1: 6763,
    twentyFiveDaysOfLEANMassWeek2: 6782,
    twentyFiveDaysOfLEANMassWeek3: 6787,
    twentyFiveDaysOfLEANMassWeek4: 6792,
    setYourNewYearGoals: 6882,
    newYearPrep: 6887,
    pilatesEvolutionPhase1: 7124,
    pilatesEvolutionWeek1: 6924,
    pilatesEvolutionWeek2: 7029,
    pilatesEvolutionWeek3: 7129,
    pilatesEvolutionWeek4: 7151,
    pilatesEvolutionPhase2: 7213,
    pilatesEvolutionWeek5: 7172,
    pilatesEvolutionWeek6: 7189,
    pilatesEvolutionWeek7: 7194,
    pilatesEvolutionDay50: 7202,
    pilatesEvolutionWeek9: 7207,
    bestOfLmpe: 7278,
    bestOfLmpeWeek1: 7241,
    bestOfLmpeWeek2: 7246,
    weekOfWellness: 7272,
    matInspiredReformerPilates: 7292,
    ultimateStrengthChallenge: 7304,
    pilatesStrengthFusion: 7345,
    lowImpactFatBurn: 7431,
    sunsOutBunsOut: 7441,
    powerUpPilatesWeek1: 7446,
    powerUpPilatesWeek2: 7475,
    powerUpPilatesWeek3: 7519,
    summerSurprisePrep: 7538,
    summerSurprisePrepw1: 7496,
    summerSurprisePrepw2: 7501,
    pilatesSummerSculptPhase1: 7554,
    pilatesSummerSculptWeek1: 7532,
    pilatesSummerSculptWeek2: 7570,
    pilatesSummerSculptWeek3: 7602,
    pilatesSummerSculptWeek4: 7635,
    pilatesSummerSculptPhase2: 7649,
    pilatesSummerSculptWeek5: 7641,
    pilatesSummerSculptWeek6: 7672,
    pilatesSummerSculptWeek7: 7690,
    pilatesSummerSculptWeek8: 7683,
    pilatesSummerSculptWeek9: 7731,
    leanLifestyle: 7740,
    coreStrengthStability: 7770,
    buildStrengthMonthWeek1: 7793,
    buildStrengthMonthWeek2: 7847,
    buildStrengthMonthWeek3: 7852,
    buildStrengthMonthWeek4: 7872,
    resetAndThriveChallenge: 7894,
    buildYourStengthMonth: 7923,
    bestOfLeanMethod: 7911,
    bestOfLeanMethodWeek1: 7916,
    bestOfLeanMethodWeek2: 7937,
    bestOfLeanMethodWeek3: 7942,
    bestOfLeanMethodWeek4: 7947,
    ultimateStrengthChallenge2: 8002,
    halloLeanHellWeek24: 8015,
    twentyOneVol4Week1: 8040,
    twentyOneVol4Week2: 8062,
    twentyOneVol4Week3: 8070,
    twentyOneVol4: 8102,
    bestOfSculptWeek1: 8039,
    bestOfSculptWeek2: 8110,
    bestOfSculptWeek3: 8124,
    bestOfSculptWeek4: 8178,
    bestOfSculptStrength: 8189,
    winterGlowUp2: 8205,
    newYearGoals: 8231,
    levelUpPrep: 8281,
    leanMethodLevelUp: 8299,
    leanMethodLevelUpW1: 8317,
    leanMethodLevelUpW2: 8352,
    leanMethodLevelUpW3: 8357,
  },
  4: {
    booty: 3094,
    sabri: 3388,
    hourglass: 3233,
    smallerWaistGuide: 3099,
    twentyOne: 3472,
    cutGuide: 3670,
    strongCapable: 4100,
    janGuide: 4150,
    strongGuide: 4188,
    janWeek3: 4203,
    week4: 4236,
    week5: 4259,
    week6: 4266,
    leanAndStrongTransformationVol14Weeks: 4532,
    galentine: 4272,
    turnItUp: 4279,
    transformationV2: 4306,
    transformationV2_week2: 4312,
    transformationV2Week3: 4323,
    transformationV2Week4: 4357,
    transformationV2Week5: 4366,
    transformationV2Week6: 4419,
    leanAndStrongTransformationVol24Weeks: 4537,
    // maryBraunWeek1: 4456,
    // maryBraunWeek2: 4473,
    deloadPilatesBootCamp: 4438,
    preSummerBootcamp: 4468,
    // maryBraunWeek3: 4524,
    // maryBraunWeek4: 4529,
    feelGoodSummerWeek1: 4549,
    feelGoodSummerWeek2: 4559,
    feelGoodSummerWeek3: 4574,
    periodFriendly: 4570,
    feelGoodSummerWeek4:4592,
    feelGoodSummerWeek5:4602,
    feelGoodSummerWeek6:4613,
    feelGoodSummer4Weeks: 4680,
    bestOfSculpt: 4651,
    bestOfShred: 4659,
    birthday:4696,
    vacay: 4701,
    vacayWeek2: 4729,
    vacayGuide4Weeks: 4843,
    glowWeek1:4744,
    glowWeek2:4756,
    glowWeek3:4786,
    glowWeek4:4791,
    greensAndGlow4Weeks: 4883,
    twentyOneVol2Week1: 4814,
    twentyOneVol2Week2: 4870,
    twentyOneVol2Week3: 4902,
    twentyOneVol24Weeks: 4994,
    gimmeGummyMore: 4923,
    septChallengeWeek1:4933,
    septChallengeWeek2:4942,
    feelGoodFitFactoryWeek3: 5030,
    feelGoodFitFactoryWeek4: 5061,
    feelGoodFitFactoryWeek5: 5083,
    feelGoodFitFactoryWeek6: 5097,
    feelGoodFitFactory4Weeks: 5140,
    halloLeanPrep: 5123,
    halloLeanHellWeek22: 5132,
    bestOfStrength: 5157,
    pilatesBody: 5192,
    busyGirlBlackFridayWeek1: 5230,
    busyGirlBlackFridayWeek2: 5247,
    busyGirlBlackFriday4Weeks: 5338,
    cardioGlowUp: 5277,
    cardioGlowUp4Weeks: 5350,
    leanAndPowerful: 5286,
    leanMass2022: 5306,
    leanMass2022Week2: 5333,
    leanMass2022Repacked: 5477,
    bestOfSculptMonth: 5357,
    leanTransformationMethodPhase1: 5516,
    leanTransformationMethodPhase2: 5611,
    leanTransformationMethodWeek1: 5360,
    leanTransformationMethodWeek2: 5460,
    leanTransformationMethodWeek3: 5465,
    leanTransformationMethodWeek4: 5470,
    leanTransformationMethodWeek5: 5538 ,
    leanTransformationMethodWeek6: 5543,
    leanTransformationMethodWeek7: 5548,
    leanTransformationMethodWeek8: 5553,
    recoverAndStrengthenPilates: 5632,
    winterGlowUp: 5689,
    lowerBody: 5697,
    upperBodyAndAbsShred: 5716,
    sculptBurnAndShape: 5742,
    ohhiit:5769,
    hourglassSculptAndStrengthen: 5787,
    ultimateWeekOfBattles: 5808,
    powerPilates: 5822,
    leanSummerMethodPhase1: 5888,
    leanSummerMethodPhase2: 5982,
    leanSummerMethodWeek1: 5832,
    leanSummerMethodWeek2: 5851,
    leanSummerMethodWeek3: 5900,
    leanSummerMethodWeek4: 5905,
    leanSummerMethodWeek5: 5933,
    leanSummerMethodWeek6: 5938,
    leanSummerMethodWeek7: 5943,
    leanSummerMethodWeek8: 5948,
    leanSecondBirthdayBash: 6051,
    twentyOneVol3: 6160,
    twentyOneVol3Week1: 6100,
    twentyOneVol3Week2: 6137,
    twentyOneVol3Week3: 6149,
    fatBurnCoreGalore: 6180,
    legsSculptAndFatBurn: 6276,
    yourStrongestSelf: 6295,
    shapeBurnPilates: 6304,
    shredWithWeights: 6338,
    hourGlassShredShape: 6355,
    absAndUpperBody: 6364,
    leanEmpoweredMethodPhase1: 6379,
    leanEmpoweredMethodPhase1Week1: 6451,
    leanEmpoweredMethodWeek3: 6475,
    leanEmpoweredMethodPhase1Week2: 6470,
    leanEmpoweredMethodPhase1Week4: 6480,
    leanEmpoweredMethodPhase2Week5: 6581,
    leanEmpoweredMethodPhase2: 6616,
    leanEmpoweredMethodPhase2Week6: 6591,
    leanEmpoweredMethodPhase2Week7: 6603,
    halloLeanHellWeek: 6626,
    leanEmpoweredMethodPhase2Week8: 6611,
    pilatesBootcampChallengeWeek1: 6742,
    pilatesBootcampChallengeWeek2: 6747,
    pilatesBootcampChallenge: 6807,
    twentyFiveDaysOfLEANMass: 6834,
    twentyFiveDaysOfLEANMassWeek1: 6764,
    twentyFiveDaysOfLEANMassWeek2: 6783,
    twentyFiveDaysOfLEANMassWeek3: 6788,
    twentyFiveDaysOfLEANMassWeek4: 6793,
    setYourNewYearGoals: 6881,
    newYearPrep: 6886,
    pilatesEvolutionPhase1: 7123,
    pilatesEvolutionWeek1: 6923,
    pilatesEvolutionWeek2: 7028,
    pilatesEvolutionWeek3: 7128,
    pilatesEvolutionWeek4: 7150,
    pilatesEvolutionPhase2: 7212,
    pilatesEvolutionWeek5: 7171,
    pilatesEvolutionWeek6: 7188,
    pilatesEvolutionWeek7: 7193,
    pilatesEvolutionDay50: 7201,
    pilatesEvolutionWeek9: 7206,
    bestOfLmpe: 7277,
    bestOfLmpeWeek1: 7240,
    bestOfLmpeWeek2: 7245,
    weekOfWellness: 7271,
    matInspiredReformerPilates: 7291,
    ultimateStrengthChallenge: 7303,
    pilatesStrengthFusion: 7346,
    lowImpactFatBurn: 7430,
    sunsOutBunsOut: 7440,
    powerUpPilatesWeek1: 7445,
    powerUpPilatesWeek2: 7474,
    powerUpPilatesWeek3: 7518,
    summerSurprisePrep: 7537,
    summerSurprisePrepw1: 7495,
    summerSurprisePrepw2: 7500,
    pilatesSummerSculptPhase1: 7553,
    pilatesSummerSculptWeek1: 7531,
    pilatesSummerSculptWeek2: 7569,
    pilatesSummerSculptWeek3: 7601,
    pilatesSummerSculptWeek4: 7634,
    pilatesSummerSculptPhase2: 7648,
    pilatesSummerSculptWeek5: 7640,
    pilatesSummerSculptWeek6: 7671,
    pilatesSummerSculptWeek7: 7689,
    pilatesSummerSculptWeek8: 7682,
    pilatesSummerSculptWeek9: 7730,
    leanLifestyle: 7739,
    coreStrengthStability: 7769,
    buildStrengthMonthWeek1: 7792,
    buildStrengthMonthWeek2: 7846,
    buildStrengthMonthWeek3: 7851,
    buildStrengthMonthWeek4: 7873,
    resetAndThriveChallenge: 7895,
    buildYourStengthMonth: 7922,
    bestOfLeanMethod: 7910,
    bestOfLeanMethodWeek1: 7915,
    bestOfLeanMethodWeek2: 7936,
    bestOfLeanMethodWeek3: 7941,
    bestOfLeanMethodWeek4: 7946,
    ultimateStrengthChallenge2: 8001,
    halloLeanHellWeek24: 8016,
    twentyOneVol4Week1: 8041,
    twentyOneVol4Week2: 8063,
    twentyOneVol4Week3: 8071,
    twentyOneVol4: 8095,
    bestOfSculptWeek1: 8038,
    bestOfSculptWeek2: 8111,
    bestOfSculptWeek3: 8123,
    bestOfSculptWeek4: 8177,
    bestOfSculptStrength: 8190,
    winterGlowUp2: 8206,
    newYearGoals: 8230,
    levelUpPrep: 8280,
    leanMethodLevelUp: 8298,
    leanMethodLevelUpW1: 8316,
    leanMethodLevelUpW2: 8351,
    leanMethodLevelUpW3: 8356,
  },
  5: {
    booty: 3093,
    sabri: 3387,
    hourglass: 3234,
    smallerWaistGuide: 3098,
    twentyOne: 3471,
    cutGuide: 3669,
    strongCapable: 4099,
    janGuide: 4149,
    strongGuide: 4151,
    janWeek3: 4202,
    week4: 4235,
    week5: 4258,
    week6: 4265,
    leanAndStrongTransformationVol14Weeks: 4533,
    galentine: 4271,
    turnItUp: 4278,
    transformationV2: 4305,
    transformationV2_week2: 4311,
    transformationV2Week3: 4322,
    transformationV2Week4: 4356,
    transformationV2Week5: 4365,
    transformationV2Week6: 4418,
    leanAndStrongTransformationVol24Weeks: 4538,
    // maryBraunWeek1: 4455,
    // maryBraunWeek2: 4472,
    deloadPilatesBootCamp: 4442,
    preSummerBootcamp: 4467,
    // maryBraunWeek3: 4523,
    // maryBraunWeek4: 4528,
    feelGoodSummerWeek1: 4548,
    feelGoodSummerWeek2: 4558,
    feelGoodSummerWeek3: 4573,
    periodFriendly: 4570,
    feelGoodSummerWeek4: 4591,
    feelGoodSummerWeek5:4600,
    feelGoodSummerWeek6:4612,
    feelGoodSummer4Weeks: 4679,
    bestOfSculpt: 4650,
    bestOfShred: 4660,
    birthday:4697,
    vacay: 4702,
    vacayWeek2: 4728,
    vacayGuide4Weeks: 4844,
    glowWeek1:4745,
    glowWeek2:4755,
    glowWeek3:4787,
    glowWeek4:4792,
    greensAndGlow4Weeks: 4882,
    twentyOneVol2Week1: 4813,
    twentyOneVol2Week2: 4869,
    twentyOneVol2Week3: 4901,
    twentyOneVol24Weeks: 4993,
    gimmeGummyMore: 4922,
    septChallengeWeek1:4932,
    septChallengeWeek2:4941,
    feelGoodFitFactoryWeek3: 5029,
    feelGoodFitFactoryWeek4: 5060,
    feelGoodFitFactoryWeek5: 5082,
    feelGoodFitFactoryWeek6: 5096,
    feelGoodFitFactory4Weeks: 5139,
    halloLeanPrep: 5124,
    halloLeanHellWeek22: 5133,
    bestOfStrength: 5158,
    pilatesBody: 5193,
    busyGirlBlackFridayWeek1: 5231,
    busyGirlBlackFridayWeek2: 5248,
    busyGirlBlackFriday4Weeks: 5339,
    cardioGlowUp: 5278,
    cardioGlowUp4Weeks: 5349,
    leanAndPowerful: 5287,
    leanMass2022: 5307,
    leanMass2022Week2: 5334,
    leanMass2022Repacked: 5476,
    bestOfSculptMonth: 5356,
    leanTransformationMethodPhase1: 5515,
    leanTransformationMethodPhase2: 5610,
    leanTransformationMethodWeek1: 5361,
    leanTransformationMethodWeek2: 5459,
    leanTransformationMethodWeek3: 5464,
    leanTransformationMethodWeek4: 5469,
    leanTransformationMethodWeek5: 5537,
    leanTransformationMethodWeek6: 5542,
    leanTransformationMethodWeek7: 5547,
    leanTransformationMethodWeek8: 5552,
    recoverAndStrengthenPilates: 5631,
    winterGlowUp: 5688,
    lowerBody: 5696,
    upperBodyAndAbsShred: 5715,
    sculptBurnAndShape: 5741,
    ohhiit:5768,
    hourglassSculptAndStrengthen: 5786,
    ultimateWeekOfBattles: 5809,
    powerPilates: 5821,
    leanSummerMethodPhase1: 5887,
    leanSummerMethodPhase2: 5983,
    leanSummerMethodWeek1: 5833,
    leanSummerMethodWeek2: 5852,
    leanSummerMethodWeek3: 5899,
    leanSummerMethodWeek4: 5904,
    leanSummerMethodWeek5: 5932,
    leanSummerMethodWeek6: 5937,
    leanSummerMethodWeek7: 5942,
    leanSummerMethodWeek8: 5947,
    leanSecondBirthdayBash: 6050,
    twentyOneVol3: 6159,
    twentyOneVol3Week1: 6101,
    twentyOneVol3Week2: 6138,
    twentyOneVol3Week3: 6148,
    fatBurnCoreGalore: 6179,
    legsSculptAndFatBurn: 6275,
    yourStrongestSelf: 6294,
    shapeBurnPilates: 6303,
    shredWithWeights: 6339,
    hourGlassShredShape: 6356,
    absAndUpperBody: 6365,
    leanEmpoweredMethodPhase1: 6378,
    leanEmpoweredMethodPhase1Week1: 6452,
    leanEmpoweredMethodWeek3: 6476,
    leanEmpoweredMethodPhase1Week2: 6471,
    leanEmpoweredMethodPhase1Week4: 6481,
    leanEmpoweredMethodPhase2Week5: 6582,
    leanEmpoweredMethodPhase2: 6615,
    leanEmpoweredMethodPhase2Week6: 6590,
    leanEmpoweredMethodPhase2Week7: 6602,
    halloLeanHellWeek: 6625,
    leanEmpoweredMethodPhase2Week8: 6610,
    pilatesBootcampChallengeWeek1: 6741,
    pilatesBootcampChallengeWeek2: 6746,
    pilatesBootcampChallenge: 6806,
    twentyFiveDaysOfLEANMass: 6833,
    twentyFiveDaysOfLEANMassWeek1: 6765,
    twentyFiveDaysOfLEANMassWeek2: 6784,
    twentyFiveDaysOfLEANMassWeek3: 6789,
    twentyFiveDaysOfLEANMassWeek4: 6794,
    setYourNewYearGoals: 6880,
    newYearPrep: 6885,
    pilatesEvolutionPhase1: 7122,
    pilatesEvolutionWeek1: 6922,
    pilatesEvolutionWeek2: 7027,
    pilatesEvolutionWeek3: 7127,
    pilatesEvolutionWeek4: 7149,
    pilatesEvolutionPhase2: 7211,
    pilatesEvolutionWeek5: 7170,
    pilatesEvolutionWeek6: 7187,
    pilatesEvolutionWeek7: 7192,
    pilatesEvolutionDay50: 7200,
    pilatesEvolutionWeek9: 7205,
    bestOfLmpe: 7276,
    bestOfLmpeWeek1: 7239,
    bestOfLmpeWeek2: 7244,
    weekOfWellness: 7270,
    matInspiredReformerPilates: 7290,
    ultimateStrengthChallenge: 7302,
    pilatesStrengthFusion: 7347,
    lowImpactFatBurn: 7429,
    sunsOutBunsOut: 7439,
    powerUpPilatesWeek1: 7444,
    powerUpPilatesWeek2: 7473,
    powerUpPilatesWeek3: 7517,
    summerSurprisePrep: 7536,
    summerSurprisePrepw1: 7494,
    summerSurprisePrepw2: 7499,
    pilatesSummerSculptPhase1: 7552,
    pilatesSummerSculptWeek1: 7530,
    pilatesSummerSculptWeek2: 7568,
    pilatesSummerSculptWeek3: 7600,
    pilatesSummerSculptWeek4: 7633,
    pilatesSummerSculptPhase2: 7647,
    pilatesSummerSculptWeek5: 7639,
    pilatesSummerSculptWeek6: 7670,
    pilatesSummerSculptWeek7: 7688,
    pilatesSummerSculptWeek8: 7681,
    pilatesSummerSculptWeek9: 7729,
    leanLifestyle: 7738,
    coreStrengthStability: 7768,
    buildStrengthMonthWeek1: 7791,
    buildStrengthMonthWeek2: 7845,
    buildStrengthMonthWeek3: 7850,
    buildStrengthMonthWeek4: 7874,
    resetAndThriveChallenge: 7896,
    buildYourStengthMonth: 7921,
    bestOfLeanMethod: 7909,
    bestOfLeanMethodWeek1: 7914,
    bestOfLeanMethodWeek2: 7935,
    bestOfLeanMethodWeek3: 7940,
    bestOfLeanMethodWeek4: 7945,
    ultimateStrengthChallenge2: 8000,
    halloLeanHellWeek24: 8017,
    twentyOneVol4Week1: 8042,
    twentyOneVol4Week2: 8068,
    twentyOneVol4Week3: 8072,
    twentyOneVol4: 8101,
    bestOfSculptWeek1: 8037,
    bestOfSculptWeek2: 8112,
    bestOfSculptWeek3: 8122,
    bestOfSculptWeek4: 8176,
    bestOfSculptStrength: 8191,
    winterGlowUp2: 8207,
    newYearGoals: 8229,
    levelUpPrep: 8279,
    leanMethodLevelUp: 8297,
    leanMethodLevelUpW1: 8315,
    leanMethodLevelUpW2: 8350,
    leanMethodLevelUpW3: 8355,
  },
  6: {
    booty: 3092,
    sabri: 3386,
    hourglass: 3235,
    smallerWaistGuide: 3097,
    twentyOne: 3470,
    cutGuide: 3668,
    strongCapable: 4098,
    pilates: 4141,
    janGuide: 4148,
    strongGuide: 4150,
    janWeek3: 4201,
    week4: 4234,
    week5: 4257,
    week6: 4264,
    leanAndStrongTransformationVol14Weeks: 4534,
    galentine: 4270,
    turnItUp: 4277,
    transformationV2: 4304,
    transformationV2_week2: 4310,
    transformationV2Week3: 4321,
    transformationV2Week4: 4355,
    transformationV2Week5: 4364,
    transformationV2Week6: 4417,
    leanAndStrongTransformationVol24Weeks: 4539,
    // maryBraunWeek1: 4454,
    // maryBraunWeek2: 4471,
    deloadPilatesBootCamp: 4443,
    preSummerBootcamp: 4466,
    // maryBraunWeek3: 4522,
    // maryBraunWeek4: 4527,
    feelGoodSummerWeek1: 4547,
    feelGoodSummerWeek2: 4557,
    feelGoodSummerWeek3: 4572,
    periodFriendly: 4570,
    feelGoodSummerWeek4: 4590,
    feelGoodSummerWeek5:4599,
    feelGoodSummerWeek6: 4611,
    feelGoodSummer4Weeks: 4678,
    bestOfSculpt: 4649,
    bestOfShred: 4661,
    birthday:4698,
    vacay: 4703,
    vacayWeek2: 4727,
    vacayGuide4Weeks: 4845,
    glowWeek1:4746,
    glowWeek2:4754,
    glowWeek3:4788,
    glowWeek4:4793,
    greensAndGlow4Weeks: 4881,
    twentyOneVol2Week1: 4812,
    twentyOneVol2Week2: 4868,
    twentyOneVol2Week3: 4900,
    twentyOneVol24Weeks: 4992,
    gimmeGummyMore: 4921,
    septChallengeWeek1:4931,
    septChallengeWeek2:4940,
    feelGoodFitFactoryWeek3: 5028,
    feelGoodFitFactoryWeek4: 5059,
    feelGoodFitFactoryWeek5: 5081,
    feelGoodFitFactoryWeek6: 5095,
    feelGoodFitFactory4Weeks: 5138,
    halloLeanPrep: 5125,
    halloLeanHellWeek22: 5134,
    bestOfStrength: 5159,
    pilatesBody: 5194,
    busyGirlBlackFridayWeek1: 5232,
    busyGirlBlackFridayWeek2: 5249,
    busyGirlBlackFriday4Weeks: 5340,
    cardioGlowUp: 5279,
    cardioGlowUp4Weeks: 5348,
    leanAndPowerful: 5288,
    leanMass2022: 5308,
    leanMass2022Week2: 5335,
    leanMass2022Repacked: 5475,
    bestOfSculptMonth: 5355,
    leanTransformationMethodPhase1: 5514,
    leanTransformationMethodPhase2: 5609,
    leanTransformationMethodWeek1: 5362,
    leanTransformationMethodWeek2: 5458,
    leanTransformationMethodWeek3: 5463,
    leanTransformationMethodWeek4: 5468,
    leanTransformationMethodWeek5: 5536,
    leanTransformationMethodWeek6: 5541,
    leanTransformationMethodWeek7: 5546,
    leanTransformationMethodWeek8: 5551,
    recoverAndStrengthenPilates: 5630,
    winterGlowUp: 5687,
    lowerBody: 5695,
    upperBodyAndAbsShred: 5714,
    sculptBurnAndShape: 5740,
    ohhiit:5767,
    hourglassSculptAndStrengthen: 5785,
    ultimateWeekOfBattles: 5810,
    powerPilates: 5820,
    leanSummerMethodPhase1: 5886,
    leanSummerMethodPhase2: 5984,
    leanSummerMethodWeek1: 5834,
    leanSummerMethodWeek2: 5853,
    leanSummerMethodWeek3: 5898,
    leanSummerMethodWeek4: 5903,
    leanSummerMethodWeek5: 5931,
    leanSummerMethodWeek6: 5936,
    leanSummerMethodWeek7: 5941,
    leanSummerMethodWeek8: 5946,
    leanSecondBirthdayBash: 6049,
    twentyOneVol3: 6158,
    twentyOneVol3Week1: 6102,
    twentyOneVol3Week2: 6139,
    twentyOneVol3Week3: 6147,
    fatBurnCoreGalore: 6178,
    fatBurnCoreGaloreWeek1: 6274,
    yourStrongestSelf: 6293,
    shapeBurnPilates: 6302,
    shredWithWeights: 6340,
    hourGlassShredShape: 6357,
    absAndUpperBody: 6366,
    leanEmpoweredMethodPhase1: 6377,
    leanEmpoweredMethodPhase1Week1: 6453,
    leanEmpoweredMethodWeek3: 6477,
    leanEmpoweredMethodPhase1Week2: 6472,
    leanEmpoweredMethodPhase1Week4: 6482,
    leanEmpoweredMethodPhase2Week5: 6583,
    leanEmpoweredMethodPhase2: 6614,
    leanEmpoweredMethodPhase2Week6: 6589,
    leanEmpoweredMethodPhase2Week7: 6601,
    halloLeanHellWeek: 6624,
    leanEmpoweredMethodPhase2Week8: 6609,
    pilatesBootcampChallengeWeek1: 6740,
    pilatesBootcampChallengeWeek2: 6745,
    pilatesBootcampChallenge: 6805,
    twentyFiveDaysOfLEANMass: 6832,
    twentyFiveDaysOfLEANMassWeek1: 6766,
    twentyFiveDaysOfLEANMassWeek2: 6785,
    twentyFiveDaysOfLEANMassWeek3: 6790,
    twentyFiveDaysOfLEANMassWeek4: 6795,
    setYourNewYearGoals: 6879,
    newYearPrep: 6884,
    pilatesEvolutionPhase1: 7121,
    pilatesEvolutionWeek1: 6921,
    pilatesEvolutionWeek2: 7026,
    pilatesEvolutionWeek3: 7126,
    pilatesEvolutionWeek4: 7148,
    pilatesEvolutionPhase2: 7210,
    pilatesEvolutionWeek5: 7169,
    pilatesEvolutionWeek6: 7186,
    pilatesEvolutionWeek7: 7191,
    pilatesEvolutionDay50: 7199,
    pilatesEvolutionWeek9: 7204,
    bestOfLmpe: 7275,
    bestOfLmpeWeek1: 7238,
    bestOfLmpeWeek2: 7243,
    weekOfWellness: 7269,
    matInspiredReformerPilates: 7289,
    ultimateStrengthChallenge: 7301,
    pilatesStrengthFusion: 7348,
    lowImpactFatBurn: 7428,
    sunsOutBunsOut: 7438,
    powerUpPilatesWeek1: 7443,
    powerUpPilatesWeek2: 7472,
    powerUpPilatesWeek3: 7516,
    summerSurprisePrep: 7535,
    summerSurprisePrepw1: 7493,
    summerSurprisePrepw2: 7498,
    pilatesSummerSculptPhase1: 7551,
    pilatesSummerSculptWeek1: 7529,
    pilatesSummerSculptWeek2: 7567,
    pilatesSummerSculptWeek3: 7599,
    pilatesSummerSculptWeek4: 7632,
    pilatesSummerSculptPhase2: 7646,
    pilatesSummerSculptWeek5: 7638,
    pilatesSummerSculptWeek6: 7669,
    pilatesSummerSculptWeek7: 7687,
    pilatesSummerSculptWeek8: 7680,
    pilatesSummerSculptWeek9: 7728,
    leanLifestyle: 7737,
    coreStrengthStability: 7767,
    buildStrengthMonthWeek1: 7790,
    buildStrengthMonthWeek2: 7844,
    buildStrengthMonthWeek3: 7849,
    buildStrengthMonthWeek4: 7875,
    resetAndThriveChallenge: 7897,
    buildYourStengthMonth: 7920,
    bestOfLeanMethod: 7908,
    bestOfLeanMethodWeek1: 7913,
    bestOfLeanMethodWeek2: 7934,
    bestOfLeanMethodWeek3: 7939,
    bestOfLeanMethodWeek4: 7944,
    ultimateStrengthChallenge2: 7999,
    halloLeanHellWeek24: 8018,
    twentyOneVol4Week1: 8043,
    twentyOneVol4Week2: 8064,
    twentyOneVol4Week3: 8073,
    twentyOneVol4: 8103,
    bestOfSculptWeek1: 8036,
    bestOfSculptWeek2: 8113,
    bestOfSculptWeek3: 8121,
    bestOfSculptWeek4: 8175,
    bestOfSculptStrength: 8192,
    winterGlowUp2: 8208,
    newYearGoals: 8228,
    levelUpPrep: 8278,
    leanMethodLevelUp: 8296,
    leanMethodLevelUpW1: 8314,
    leanMethodLevelUpW2: 8349,
    leanMethodLevelUpW3: 8354,
  },
  7: {
    twentyOne: 3469,
    halloLean: 3484,
    cutGuide: 3667,
    leanMas: 4115,
    janWeek3: 4200,
    week4: 4233,
    week5: 4256,
    week6: 4263,
    leanAndStrongTransformationVol14Weeks: 4535,
    galentine: 4269,
    turnItUp: 4276,
    transformationV2: 4303,
    transformationV2_week2: 4309,
    transformationV2Week3: 4320,
    transformationV2Week4: 4352,
    transformationV2Week5: 4363,
    transformationV2Week6: 4416,
    leanAndStrongTransformationVol24Weeks: 4540,
    // maryBraunWeek1: 4453,
    // maryBraunWeek2: 4470,
    deloadPilatesBootCamp: 4444,
    preSummerBootcamp: 4465,
    // maryBraunWeek3: 4521,
    // maryBraunWeek4: 4526,
    feelGoodSummerWeek1: 4546,
    feelGoodSummerWeek2: 4556,
    feelGoodSummerWeek3: 4571,
    periodFriendly: 4570,
    feelGoodSummerWeek4: 4589,
    feelGoodSummerWeek5:4598,
    feelGoodSummerWeek6:4610,
    feelGoodSummer4Weeks: 4677,
    bestOfSculpt: 4648,
    bestOfShred: 4662,
    birthday:4699,
    vacay: 4704,
    vacayWeek2: 4726,
    vacayGuide4Weeks: 4846,
    glowWeek1:4747,
    glowWeek2:4753,
    glowWeek3:4789,
    glowWeek4:4794,
    greensAndGlow4Weeks: 4880,
    twentyOneVol2Week1: 4811,
    twentyOneVol2Week2: 4867,
    twentyOneVol2Week3: 4899,
    twentyOneVol24Weeks: 4991,
    gimmeGummyMore: 4920,
    septChallengeWeek1:4930,
    septChallengeWeek2:4939,
    feelGoodFitFactoryWeek3: 5016,
    feelGoodFitFactoryWeek4: 5058,
    feelGoodFitFactoryWeek5: 5080,
    feelGoodFitFactoryWeek6: 5094,
    feelGoodFitFactory4Weeks: 5137,
    halloLeanPrep: 5126,
    halloLeanHellWeek22: 5135,
    bestOfStrength: 5160,
    pilatesBody: 5195,
    busyGirlBlackFridayWeek1: 5233,
    busyGirlBlackFridayWeek2: 5250,
    busyGirlBlackFriday4Weeks: 5341,
    cardioGlowUp: 5280,
    cardioGlowUp4Weeks: 5342,
    leanAndPowerful: 5289,
    leanMass2022: 5309,
    leanMass2022Week2: 5336,
    leanMass2022Repacked: 5474,
    bestOfSculptMonth: 5354,
    leanTransformationMethodPhase1: 5513,
    leanTransformationMethodPhase2: 5608,
    leanTransformationMethodWeek1: 5363,
    leanTransformationMethodWeek2: 5457,
    leanTransformationMethodWeek3: 5462,
    leanTransformationMethodWeek4: 5467,
    leanTransformationMethodWeek5: 5535,
    leanTransformationMethodWeek6: 5540,
    leanTransformationMethodWeek7: 5545,
    leanTransformationMethodWeek8: 5550,
    recoverAndStrengthenPilates: 5629,
    winterGlowUp: 5681,
    lowerBody: 5694,
    upperBodyAndAbsShred: 5713,
    sculptBurnAndShape: 5739,
    ohhiit:5766,
    hourglassSculptAndStrengthen: 5784,
    ultimateWeekOfBattles: 5811,
    powerPilates: 5819,
    leanSummerMethodPhase1: 5885,
    leanSummerMethodPhase2: 5985,
    leanSummerMethodWeek1: 5835,
    leanSummerMethodWeek2: 5854,
    leanSummerMethodWeek3: 5897,
    leanSummerMethodWeek4: 5902,
    leanSummerMethodWeek5: 5930,
    leanSummerMethodWeek6: 5935,
    leanSummerMethodWeek7: 5940,
    leanSummerMethodWeek8: 5945,
    leanSecondBirthdayBash: 6044,
    twentyOneVol3: 6157,
    twentyOneVol3Week1: 6103,
    twentyOneVol3Week2: 6140,
    twentyOneVol3Week3: 6146,
    fatBurnCoreGalore: 6177,
    fatBurnCoreGaloreWeek1: 6273,
    yourStrongestSelf: 6292,
    shapeBurnPilates: 6301,
    shredWithWeights: 6341,
    hourGlassShredShape: 6358,
    absAndUpperBody: 6367,
    leanEmpoweredMethodPhase1: 6368,
    leanEmpoweredMethodPhase1Week1: 6454,
    leanEmpoweredMethodWeek3: 6478,
    leanEmpoweredMethodPhase1Week2: 6473,
    leanEmpoweredMethodPhase1Week4: 6483,
    leanEmpoweredMethodPhase2Week5: 6584,
    leanEmpoweredMethodPhase2: 6613,
    leanEmpoweredMethodPhase2Week6: 6588,
    leanEmpoweredMethodPhase2Week7: 6600,
    halloLeanHellWeek: 6623,
    leanEmpoweredMethodPhase2Week8: 6605,
    pilatesBootcampChallengeWeek1: 6739,
    pilatesBootcampChallengeWeek2: 6744,
    pilatesBootcampChallenge: 6804,
    twentyFiveDaysOfLEANMass: 6831,
    twentyFiveDaysOfLEANMassWeek1: 6767,
    twentyFiveDaysOfLEANMassWeek2: 6786,
    twentyFiveDaysOfLEANMassWeek3: 6791,
    twentyFiveDaysOfLEANMassWeek4: 6796,
    setYourNewYearGoals: 6877,
    newYearPrep: 6883,
    pilatesEvolutionPhase1: 7120,
    pilatesEvolutionWeek1: 6920,
    pilatesEvolutionWeek2: 7014,
    pilatesEvolutionWeek3: 7125,
    pilatesEvolutionWeek4: 7147,
    pilatesEvolutionPhase2: 7209,
    pilatesEvolutionWeek5: 7168,
    pilatesEvolutionWeek6: 7185,
    pilatesEvolutionWeek7: 7190,
    pilatesEvolutionDay50: 7198,
    pilatesEvolutionWeek9: 7203,
    bestOfLmpe: 7274,
    bestOfLmpeWeek1: 7237,
    bestOfLmpeWeek2: 7242,
    weekOfWellness: 7268,
    matInspiredReformerPilates: 7279,
    ultimateStrengthChallenge: 7300,
    pilatesStrengthFusion: 7349,
    lowImpactFatBurn: 7427,
    sunsOutBunsOut: 7437,
    powerUpPilatesWeek1: 7442,
    powerUpPilatesWeek2: 7471,
    powerUpPilatesWeek3: 7515,
    summerSurprisePrep: 7534,
    summerSurprisePrepw1: 7492,
    summerSurprisePrepw2: 7497,
    pilatesSummerSculptPhase1: 7550,
    pilatesSummerSculptWeek1: 7528,
    pilatesSummerSculptWeek2: 7566,
    pilatesSummerSculptWeek3: 7598,
    pilatesSummerSculptWeek4: 7631,
    pilatesSummerSculptPhase2: 7645,
    pilatesSummerSculptWeek5: 7637,
    pilatesSummerSculptWeek6: 7668,
    pilatesSummerSculptWeek7: 7686,
    pilatesSummerSculptWeek8: 7679,
    pilatesSummerSculptWeek9: 7727,
    leanLifestyle: 7736,
    coreStrengthStability: 7766,
    buildStrengthMonthWeek1: 7789,
    buildStrengthMonthWeek2: 7843,
    buildStrengthMonthWeek3: 7848,
    buildStrengthMonthWeek4: 7876,
    resetAndThriveChallenge: 7898,
    buildYourStengthMonth: 7919,
    bestOfLeanMethod: 7902,
    bestOfLeanMethodWeek1: 7912,
    bestOfLeanMethodWeek2: 7933,
    bestOfLeanMethodWeek3: 7938,
    bestOfLeanMethodWeek4: 7943,
    ultimateStrengthChallenge2: 7998,
    halloLeanHellWeek24: 8019,
    twentyOneVol4Week1: 8044,
    twentyOneVol4Week2: 8065,
    twentyOneVol4Week3: 8074,
    twentyOneVol4: 8104,
    bestOfSculptWeek1: 8035,
    bestOfSculptWeek2: 8114,
    bestOfSculptWeek3: 8120,
    bestOfSculptWeek4: 8174,
    bestOfSculptStrength: 8193,
    winterGlowUp2: 8209,
    newYearGoals: 8227,
    levelUpPrep: 8277,
    leanMethodLevelUp: 8292,
    leanMethodLevelUpW1: 8307,
    leanMethodLevelUpW2: 8348,
    leanMethodLevelUpW3: 8353,
  },

};

export const GUIDE_FIND_WORKOUT_FREQUENCY = (productID) =>
  Object?.keys(GUIDE_MAP)?.find((key) => Object?.values(GUIDE_MAP?.[key])?.indexOf(productID) > -1);

export const GUIDES_ORDER = [];
// [
//     GUIDE_MAP?.['3']?.smallerWaistGuide,
//     GUIDE_MAP?.['4']?.smallerWaistGuide,
//     GUIDE_MAP?.['5']?.smallerWaistGuide,
//     GUIDE_MAP?.['6']?.smallerWaistGuide,
//     GUIDE_MAP?.['3']?.booty,
//     GUIDE_MAP?.['4']?.booty,
//     GUIDE_MAP?.['5']?.booty,
//     GUIDE_MAP?.['6']?.booty,
//     GUIDE_MAP?.['3']?.hourglass,
//     GUIDE_MAP?.['4']?.hourglass,
//     GUIDE_MAP?.['5']?.hourglass,
//     GUIDE_MAP?.['6']?.hourglass,
// ];

// export const staging = {
//   aws: {
//     url: "https://rm56uphfwjfrnfe2msn3a6g6bi.appsync-api.eu-west-1.amazonaws.com/graphql",
//     region: "eu-west-1",
//     type: "AWS_IAM",
//   },
//   cognito: {
//     identityPoolId: "eu-west-1:4936b8f4-66cd-4175-84e5-7a983eb47180",
//     region: "eu-west-1",
//     userPoolId: "eu-west-1_3sxDScGLH",
//     userPoolWebClientId: "4jgkebm1mh2v2r8vq05f4q21b3",
//     mandatorySignIn: "false",
//   },
//   forgotPassword: "https://jhhs6d8jmb.execute-api.eu-west-1.amazonaws.com/dev",
//   verificationsBaseURL: "https://verifications.staging.genflow.com",
// };
export const staging = {
  aws: {
    url: "https://uyuh4pjnwnd3fdltnovspiihwu.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    type: "AWS_IAM",
  },
  cognito: {
    identityPoolId: "eu-west-1:8429ef9e-4f57-45f6-8354-4ec9b80a5fb9",
    region: "eu-west-1",
    userPoolId: "eu-west-1_lVdmqTrRz",
    userPoolWebClientId: "52veqmv4vo352fqmsevpr7vjk3",
    mandatorySignIn: "false",
  },
  forgotPassword: "https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod",
  verificationsBaseURL: "https://verifications.aws.genflow.com",
};
export const production = {
  aws: {
    url: "https://uyuh4pjnwnd3fdltnovspiihwu.appsync-api.eu-west-1.amazonaws.com/graphql",
    region: "eu-west-1",
    type: "AWS_IAM",
  },
  cognito: {
    identityPoolId: "eu-west-1:8429ef9e-4f57-45f6-8354-4ec9b80a5fb9",
    region: "eu-west-1",
    userPoolId: "eu-west-1_lVdmqTrRz",
    userPoolWebClientId: "52veqmv4vo352fqmsevpr7vjk3",
    mandatorySignIn: "false",
  },
  forgotPassword: "https://7efpy5nte8.execute-api.eu-west-1.amazonaws.com/prod",
  verificationsBaseURL: "https://verifications.aws.genflow.com",
};

export const subscription = {
  rules: SUBSCRIPTION_RULES,
  hasSubscription: useHasSubscription,
};

export default {
  ...env,
  production,
  staging,
  subscription,
  GUIDE_MAP,
  GUIDES_ORDER,
  PHYSIO_COURSE_PRODUCT_ID,
  LEARN_EXERCISES_COURSE_PRODUCT_ID,
  NUTRITION_COURSE_PRODUCT_ID,
  FREE_BANDS_EDUCATION_COURSE_PRODUCT_ID,
};
